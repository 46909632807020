import React from "react";
import PropTypes from "prop-types";
import SelectField from "../../../registration/Inputs/SelectField";
import { useFormContext } from "react-hook-form";
import InputCurrencyField from "../../../registration/Inputs/InputCurrencyField";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import FileIcon from "../../../common/FileIcon";
import UploadFile from "../../../common/uploadFile/uploadFile";
import {
  useDeleteSourceMutation,
  useGetIncomeBankingQuery,
} from "../../../../services/identificationApi";
import { TiDelete } from "react-icons/ti";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { RiInformationLine } from "react-icons/ri";
import InputField from "../../../registration/Inputs/InputField";
import { ErrorMessage } from "@hookform/error-message";
import RadioInputField from "../../../registration/Inputs/RadioInputField";
import { JSONTree } from "react-json-tree";

const Source = ({ field, index }) => {
  const [otherIncomeProof, setOtherIncomeProof] = React.useState(false);
  const { register, watch, getValues, formState, setValue } = useFormContext();
  const [doDelete, deleteResult] = useDeleteSourceMutation();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const { data } = useGetIncomeBankingQuery(trn_id);
  console.log(deleteResult, "deleteresult23");
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="blue-box pd15 mr-t20">
          <div className="row">
            <div className="col-sm-12">
              <span className="s-no-index float-left">{index + 1}</span>{" "}
              {deleteResult.isUninitialized === true ? (
                <button
                  type="button"
                  onClick={async () => {
                    setOpen(true);
                  }}
                  title="Delete this Employer"
                  className="float-right btn btn-sm btn-danger"
                >
                  <TiDelete size={25} /> Delete
                </button>
              ) : (
                <button disabled className="float-right btn btn-sm btn-danger">
                  <TiDelete size={25} /> Deleting...
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <SelectField
                  options={[
                    { label: "Alimony", value: "Alimony" },
                    { label: "Child Support", value: "Child Support" },
                    { label: "Pension", value: "Pension" },
                    { label: "Rental Assistance", value: "Rental Assistance" },
                    { label: "SSI/Disability", value: "SSI/Disability" },
                    { label: "Unemployment", value: "Unemployment" },
                    { label: "Other", value: "Other" },
                  ]}
                  label="Source"
                  register={register(
                    `other_income_details.otherincome[${index}].other_income_type`
                  )}
                />
              </div>
            </div>
            {watch(`other_income_details.otherincome[${index}].other_income_type`)?.value == 'Other' && (<div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b30">
                <InputField
                  label="Other Source"
                  register={register(
                    `other_income_details.otherincome[${index}].other_source`
                  )}
                />
              </div>
            </div>)}
            {watch(`other_income_details.otherincome[${index}].other_income_type`)?.value == 'Pension' && (<div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b30">
                <InputField
                  label="Pension Source"
                  register={register(
                    `other_income_details.otherincome[${index}].pension_source`
                  )}
                />
              </div>
            </div>)}
            {watch(`other_income_details.otherincome[${index}].other_income_type`)?.value == 'Rental Assistance' && (
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group cus-input-group mr-b20">
                  <SelectField
                    options={[
                      { label: "TRA", value: "TRA" },
                      { label: "Social Services/Welfare", value: "Social Services/Welfare" },
                      {
                        label: "Section 8",
                        value: "Section 8",
                      },
                      { label: "Other", value: "Other" },
                    ]}
                    label="Rental assistance type"
                    register={register(
                      `other_income_details.otherincome[${index}].rental_assistance_type`
                    )}
                  />
                </div>
              </div>
            )}
            {watch(`other_income_details.otherincome[${index}].rental_assistance_type`)?.value == 'Other' && watch(`other_income_details.otherincome[${index}].other_income_type`)?.value == 'Rental Assistance' && (<div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b30">
                <InputField
                  label="Other Rental Assistance"
                  register={register(
                    `other_income_details.otherincome[${index}].other_rental_assistance`
                  )}
                />
              </div>
            </div>)}
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b30">
                <InputCurrencyField
                  label="Monthly Income"
                  suffix="$"
                  register={register(
                    `other_income_details.otherincome[${index}].monthly_income`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <CustomRadioButton
                disable={getValues(
                  `other_income_details.otherincome.${index}.fileCount`
                )}
                register={register(
                  `other_income_details.otherincome.${index}.availableToUpload`
                )}
                label="Do you have a Proof of Other Income available to upload ?"
              />
            </div>
            {watch(
              `other_income_details.otherincome.${index}.availableToUpload`
            ) == "Yes" && (
                <>
                  <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-9">
                      <div className="ssn-text-block mt-4">
                        <h4>
                          Proof of Other Income:
                          <span className="red">*</span>
                          <RiInformationLine
                            className="ms-2 cursor-pointer"
                            fill="#D3AF51"
                            size={20}
                            onClick={() => setOtherIncomeProof(true)}
                          />
                        </h4>
                        <h5>(covering 4 most recent weeks)</h5>
                        <small
                          style={{
                            background: "yellow",
                            color: "black",
                          }}
                        >
                          You can upload PDF proof of other income here.  In order to verify other income, PDF bank statements are required.  Please be sure to enter banking information in the Banking section below, and upload PDF bank statements covering the 3 most recent months downloaded directly from your bank account website.
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center mt-4">
                      <div className="ssn-btn">
                        <UploadFile
                          label="Upload Back"
                          className="mr-b10"
                          documentId={7}
                          SourceId={index + 1}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={` ${useWindowWidth.windowWidth < 986 && `justify-content-center`
                      } d-flex flex-wrap`}
                  >
                    {data?.other_income_details?.otherincome[index]?.files.map(
                      (item, index) => {
                        return (
                          <FileIcon
                            key={item.application_document_id}
                            fileObj={item}
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}

            {watch(
              `other_income_details.otherincome.${index}.availableToUpload`
            ) == "No" && (
                <p>
                  {" "}
                  <RadioInputField
                    options={[
                      {
                        value:
                          "I don't currently have access to document(s), but can provide later",
                      },
                      {
                        value: "I do not have proof of employment",
                      },
                      {
                        value: "other",
                        label: "Other"
                      },
                    ]}
                    name={`other_income_details.otherincome.${index}.no_doc_reason`}
                  />
                  {watch(
                    `other_income_details.otherincome.${index}.no_doc_reason`
                  ) === "other" && (
                      <div>
                        <InputField
                          label="Please Explain"
                          register={register(
                            `other_income_details.otherincome.${index}.other_reason`
                          )}
                          placeholder="Please Explain"
                        />
                      </div>
                    )}
                </p>
              )}
            <Stack
              sx={{ width: "100%" }}
              style={{ textTransform: "capitalize", marginTop: "10px" }}
              spacing={2}
            >
              <ErrorMessage
                errors={formState.errors}
                name={`other_income_details.otherincome.${index}.files`}
                render={({ message }) => {
                  if (message) return <Alert severity="error">{message}</Alert>;

                  return <></>;
                }}
              />
            </Stack>
          </div>
        </div>
      </div>
     
      <Dialog
        open={open}
        onClose={() => deleteResult.isLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete non-employment  ${index + 1} ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    setOpen(false);
                }}
            >
                No
            </Button>
            <Button
                onClick={async () => {
                    // Prevent multiple delete calls
                    if (isDeleting) return;

                    setIsDeleting(true);
                    setOpen(false);

                    await doDelete({
                        trn_id: trn_id,
                        source_id: index + 1,
                        document_id: "7",
                    })
                        .then((deleteDocResponse) => {
                            if (
                                deleteDocResponse.data.status === 200 &&
                                getValues(`other_income_details.otherincome`)?.length === 1
                            ) {
                                setValue(`other_income_details.response`, "No");
                            }
                        })
                        .catch((deleteDocError) =>
                            console.log(
                                "Delete Doc Error in Banking Source",
                                deleteDocError
                            )
                        )
                        .finally(() => {
                            setIsDeleting(false); // Reset the flag after deletion
                        });
                }}
                autoFocus
                disabled={isDeleting} // Disable the button while deleting
            >
                Yes
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={otherIncomeProof}
        onClose={() => {
          setOtherIncomeProof(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            PDF bank statements covering 2-3 most recent months downloaded directly from your bank account website are required to verify non-employment income.  Please be sure to add bank account information below and please be sure to upload PDF bank statements for each bank account entered.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setOtherIncomeProof(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Source.propTypes = {};

export default Source;
