import React, { forwardRef } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import "./index.scss";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import InputMask from "react-input-mask";
import { JSONTree } from "react-json-tree";
const formatDate = (date) => {
  if (!date) return "";
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const parseDate = (str) => {
  if (!str) return null;
  // Check if the date is in the format mm/dd/yyyy
  const dateParts = str.split('/');
  if (dateParts.length === 3) {
    const [month, day, year] = dateParts;
    return new Date(year, month - 1, day);
  }

  // Check if the date is in the ISO 8601 format
  // Check if the date is in the ISO 8601 format
  const isoDate = new Date(str);
  if (!isNaN(isoDate)) {
    const month = String(isoDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(isoDate.getUTCDate()).padStart(2, '0');
    const year = isoDate.getUTCFullYear();
    return new Date(year, month - 1, day);
  }

  return null; // Handle invalid date strings
};

const ExampleCustomInput = forwardRef((inputFun, ref) => (
  <InputMask mask={"99/99/9999"} placeholder="mm/dd/yyyy" {...inputFun} inputRef={ref} />
));

const InputDateField = (props) => {
  const { name } = props.register;
  const { control, setValue } = useFormContext();
  const required = props.notRequired === undefined ? true : !props.notRequired;
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const error = fieldState.error !== undefined;
          const { onChange, value, ref, ...rest } = field;
          return (
            <>
              <label
                htmlFor={name}
                className={`form-label ${error && "text-danger"}`}
              >
                {props.label} {required && <span className="red">*</span>}
              </label>
              <ReactDatePicker
                ref={(elem) => {
                  elem && ref(elem.input);
                }}
                customInput={<ExampleCustomInput />}
                maxDate={props.maxDate}
                minDate={props.minDate}
                placeholderText='MM/DD/YYYY'
                selected={parseDate(value)}
                onChange={(date) => onChange(formatDate(date))}
                dateFormat="MM/dd/yyyy"
                showYearDropdown
                className={`form-control cus-input-control ${required && !field.value && "is-warning"
                  } ${error && "is-invalid"}`}
              />

              {/* <ReactDatePicker
                ref={(elem) => {
                  elem && ref(elem.input);
                }}
                maxDate={props.maxDate}
                minDate={props.minDate}
                placeholderText='MM/DD/YYYY'
                onChange={(date) => {
                  const utcDate = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    0,
                    0,
                    0
                  );

                  // Here you would typically send `utcDate` to your API
                  console.log('UTC Date:', utcDate.toISOString());
                  field.onChange(utcDate)
                  //moment.utc(date).format('MM/DD/YYYY')
                }}
                selected={field.value ? moment(field.value).toDate() : ''}
                showYearDropdown
                dateFormat="MM/dd/yyyy"
                className={`form-control cus-input-control ${required && !field.value && "is-warning"
                  } ${error && "is-invalid"}`}
                customInput={<ExampleCustomInput />}
                timeZone=""
              /> */}
              <div id={name + 'btn'} className="form-text">Please enter date in MM/DD/YYYY format.</div>

              <div id={name + 'btn'} className="invalid-feedback">
                {error && fieldState?.error?.message}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default InputDateField;


