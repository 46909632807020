// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API } from "../config";
import { logout, token } from "../store/registration/logindSlice";

const BASE_URL = BASE_URL_API;


export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const {
      persistedReducer: {
        loginSlice: { email, token },
      },
    } = getState();
    const accessToken = token;
    if (accessToken) {
      headers.set("email", email);
      headers.set("authorization", `${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      {
        url: "token/refresh/",
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      api.dispatch(token(refreshResult.data.records.token));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout(true));
    }
  }
  return result;
};

export const mlApi = createApi({
  reducerPath: "mlApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    uploadFileMl: builder.mutation({
      query: (body) => {
        return {
          url: "/ml/predictclass",
          method: "POST",
          body: body,
        };
      },
    }),
    getEmailList: builder.query({
      query: (body) => ({
        url: `/ml/mlbanklist`,
      }),
    }),
  }),
  
});

export const {
  useUploadFileMlMutation,
  useGetEmailListQuery,
} = mlApi;

export default mlApi;
