import React, { useEffect, useState } from "react";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import Source from "./employer-source";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import {
  useAddEmployerSourceMutation,
  useDeleteSourceMutation,
} from "../../../../services/identificationApi";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";
import {
  AccordionDetails,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import Loader from "react-loader-spinner";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import InputField from "../../../registration/Inputs/InputField";
import RadioInputField from "../../../registration/Inputs/RadioInputField";

const EmployementIncome = (props) => {
  const [deleteEmployment, setDeleteEmployment] = React.useState(false);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const [addEmployerSource, employerAddResult] = useAddEmployerSourceMutation();
  const [disabledSouceBtn,setDisabledSourceBtn] = useState(false);
  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "employment_details.employer", // unique name for your Field Array
    keyName: "controlId", //default to "id", you can change the key name
  });

  const noReasonField = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "employment_details.newEmployer", // unique name for your Field Array
    keyName: "controlId", //default to "id", you can change the key name
  });

  // const employmentNoDocArray = [
  //   {
  //     id: 1,
  //     checked: `${
  //       watch(`employment_details.no_doc_reason`) ==
  //       "I am currently not employed"
  //         ? true
  //         : false
  //     }`,
  //     value: "I am currently not employed",
  //     label: "I am currently not employed",
  //   },
  //   {
  //     id: 2,
  //     checked: `${
  //       watch(`employment_details.no_doc_reason`) == "I am starting new Job"
  //         ? true
  //         : false
  //     }
  //    `,
  //     value: "I am starting new Job",
  //     label: "I am starting new Job",
  //   },
  //   {
  //     id: 3,
  //     checked: `${
  //       watch(`employment_details.no_doc_reason`) == "I am retired"
  //         ? true
  //         : false
  //     }
  //     `,
  //     value: "I am retired",
  //     label: "I am retired",
  //   },
  //   {
  //     id: 4,
  //     checked: `${
  //       watch(`employment_details.no_doc_reason`) == "Other" ? true : false
  //     }
  //     `,
  //     value: "Other",
  //     label: "Other",
  //   },
  // ];

  const [doDelete, deleteResult] = useDeleteSourceMutation();

  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );

  const deleteAllEmploymentSource = async () => {
    let allEmployment = await getValues(`employment_details.employer`);
    if (allEmployment?.length > 0) {
      for (let index = 0; index < allEmployment?.length; index++) {
        const element = allEmployment[index];
        await doDelete({
          trn_id: trn_id,
          source_id: element.id,
          document_id: "3",
        });
      }
      setDeleteEmployment(false);
      setValue(`employment_details.response`, "No");
    }
  };

  const [nextSection, setNextSection] = React.useState(false);
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  const navigate = useNavigate();
  // highlight-starts
  const debouncedSave = React.useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          setNextSection(await props.checkValidation("Income", false));
        })(),
      1000
    ),
    [] // will be created only once initially
  );
  // highlight-ends

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     if (type === "change" && name === `employment_details.response`) {
  //       if (watch(`employment_details.response`) === "No") {
  //         setValue(
  //           "employment_details.no_doc_reason",
  //           "I am currently not employed"
  //         );
  //       }
  //     }
  //   });

  //   return () => subscription.unsubscribe();
  // }, [getValues, setValue, watch]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log("NAMECHANGE:156", name);
      debouncedSave(name);
      if (type === "change" && name === `employment_details.response`) {
        if (getValues(`employment_details.response`) === "Yes") {
          console.log("fields.length",fields.length)
          addEmployerSource({
            trn_id: trn_id,
            document_id: "3",
            source_id: fields.length+1,
          });
        }
      }
    });
    console.log("employerAddResult",employerAddResult)
    console.log("fields2",fields)
    return () => subscription.unsubscribe();
  }, [addEmployerSource, fields?.length, getValues, setValue, trn_id, watch]);

  useEffect(()=>{
  setDisabledSourceBtn(false);
  },[fields?.length])
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <AccordionDetails>
      <div className="ei-body">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <CustomRadioButton
              onClick={() => {
                if (watch("employment_details.employerCount") > 0) {
                  // setDeleteEmployment(true);
                  setOpen(true);
                }
              }}
              disable={getValues(`employment_details.employerCount`)}
              register={register(`employment_details.response`)}
              label="Are you currently employed, self employed, or starting a new job?"
            />
          </div>
          {watch(`employment_details.response`) === "Yes" && (
            <>
              {fields.map((field, index) => (
                <Source key={field.controlId} field={field} index={index} />
              ))}
              {fields?.length <= 2 && (
                <div className="col-sm-12">
                  <div className="add-text-btn flex1 text-center mr-t70">
                    {employerAddResult.isLoading ? (
                      <>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      </>
                    ) : (
                      <>
                     { fields?.length >=1 &&  <button
                          type="button"
                          onClick={(event) =>{
                            setDisabledSourceBtn(true)
                            addEmployerSource(
                              {
                              trn_id: trn_id,
                              document_id: "3",
                              source_id: fields.length+1, 
                              allowOfferLetter:false
                            })
                          }}
                          disabled={disabledSouceBtn}
                          
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                              fill="#2DC3E8"
                            ></path>
                          </svg>
                          Add another Employer
                        </button>}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {watch(`employment_details.response`) === "No" && (
            <>
              <p>
                {" "}
                <RadioInputField
                  options={[
                    {
                      value: "I am not currently employed",
                    },
                    {
                      value: "I am retired",
                    },
                    {
                      value: "Full-Time Student",
                    },
                    // {
                    //   value: "I am retired",
                    // },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                  name="employment_details.no_doc_reason"
                />
                {watch(`employment_details.no_doc_reason`) === "Other" && (
                  <div>
                    <InputField
                      label="Please Explain"
                      register={register(`employment_details.other_reason`)}
                      placeholder="Please Explain"
                    />
                  </div>
                )}
              </p>
            </>
          )}

          <Stack
            sx={{ width: "100%" }}
            style={{ textTransform: "capitalize", marginTop: "10px" }}
            spacing={2}
          >
            <ErrorMessage
              errors={errors}
              name="employment_details.employer"
              render={({ message }) => {
                if (message) return <Alert severity="error">{message}</Alert>;

                return <></>;
              }}
            />
            <ErrorMessage
              errors={errors}
              name="employment_details.employer.files"
              render={({ message }) => {
                if (message) return <Alert severity="error">{message}</Alert>;

                return <></>;
              }}
            />
          </Stack>
        </div>
      </div>
      {/* <Dialog
        open={deleteEmployment}
        onClose={() => {
          setDeleteEmployment(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setDeleteEmployment(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              deleteAllEmploymentSource();
            }}
          >
            {deleteResult.isLoading ? (
              <Loader
                type="Circles"
                color="#FFFFFF"
                height={20}
                width={20}
                radius={20}
                timeout={3000}
              />
            ) : (
              "Yes"
            )}
          </button>
        </DialogActions>
      </Dialog> */}
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You must delete all existing sources first.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValue(`employment_details.response`, "Yes");
              // setConfirmDelete(false); // Ensures response does not change
              handleClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {viewOnlyFlag || nextSection && (
        <Button
          onClick={() => {
            navigate("#other-in");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab
        </Button>
      )}
    </AccordionDetails>
  );
};

export default EmployementIncome;
