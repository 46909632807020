import React, { useCallback, useEffect, useState } from "react";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import Applying from "./applying/applying";
import { useFormContext } from "react-hook-form";
import CoOccupant from "./cooccupant/cooccupant";
import CoSigner from "./cosigner/cosigner";
import { useSelector } from "react-redux";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";

const ApplicantDetail = (props) => {
  const { register, watch, getValues, setValue, formState } = useFormContext();
  const [question, setQuestions] = useState(props.que);
  const [over18, setOver18] = useState(false);
  const [under18, setUnder18] = useState(false);
  const [coSinger, setCosinger] = useState(false);
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  const [nextSection, setNextSection] = useState(false);
  const navigate = useNavigate();
  // highlight-starts
  const debouncedSave = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          setNextSection(
            await props.checkValidation("ApplicantDetails", false)
          );
        })(),
      1000
    ),
    [] // will be created only once initially
  );
  // highlight-ends

  const checkSourceAvailableHandler = (section) => {
    if (section == "applying" && watch("anyone_over_18.fields")?.length == 0) {
      setValue("anyone_over_18.response", "No");
    }
    if (section == "cooccupant" && watch("co_occupants.fields")?.length == 0) {
      setValue("co_occupants.response", "No");
    }
    if (section == "cosigner" && watch("is_co_signer.fields")?.length == 0) {
      setValue("is_co_signer.response", "No");
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      debouncedSave(name);
      if (type === "change" && name === `anyone_over_18.response`) {
        if (getValues(`anyone_over_18.response`) === "Yes")
          setValue("anyone_over_18.fields", [
            { id: 0, first_name: "", last_name: "", email: "", mobile: null },
          ]);
      } else if (type === "change" && name === `co_occupants.response`) {
        if (getValues(`co_occupants.response`) === "Yes")
          setValue("co_occupants.fields", [
            {
              id: 0,
              first_name: "",
              last_name: "",
              email: "",
              mobile: null,
              dob: null,
            },
          ]);
      } else if (type === "change" && name === `is_co_signer.response`) {
        //is_co_signer.fields
        if (getValues(`is_co_signer.response`) === "Yes")
          setValue("is_co_signer.fields", [
            { id: 0, first_name: "", last_name: "", email: "", mobile: null },
          ]);
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch]);
  return (
    <AccordionDetails>
      <div>
        <div className="row">
          {getValues("anyone_over_18.display") && (
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div
                className="i-a-block"
              >
                <CustomRadioButton
                  notRequired={!getValues(`anyone_over_18.required`)}
                  onClick={() => {
                    if (watch("anyone_over_18.fields")?.length > 0 && !getValues( `anyone_over_18.fields`).find((item,index,arr)=>{
                     return item.is_fixed===true
                    })) {
                      setUnder18(false);
                      setCosinger(false);
                      setOver18(true);
                    }
                  }}
                  disable={watch("anyone_over_18.fields")?.length > 0 }
                  label={`${getValues(
                    `anyone_over_18.labelIndex`
                  )}. Are you applying with anyone over the age of 18?`}
                  register={register("anyone_over_18.response")}
                />
              </div>

              {watch("anyone_over_18.response") === "Yes" && (
                <Applying
                  register={register}
                  checkSourceAvailableHandler={checkSourceAvailableHandler}
                />
              )}
            </div>
          )}

          {getValues("co_occupants.display") && (
            <div className="col-sm-12">
              <div className="i-a-block mr-t30">
                <CustomRadioButton
                  notRequired={!getValues(`co_occupants.required`)}
                  onClick={() => {
                    if (watch("co_occupants.fields")?.length > 0) {
                      setOver18(false);
                      setCosinger(false);
                      setUnder18(true);
                    }
                  }}
                  disable={watch("co_occupants.fields")?.length > 0}
                  label={`${getValues(
                    `co_occupants.labelIndex`
                  )}. Will any co-occupants UNDER the age of 18 be residing with you?`}
                  register={register("co_occupants.response")}
                />
              </div>

              {watch("co_occupants.response") === "Yes" && (
                <CoOccupant
                  register={register}
                  checkSourceAvailableHandler={checkSourceAvailableHandler}
                />
              )}
            </div>
          )}

          {getValues("is_co_signer.display") && (
            <div className="col-sm-12">
              <div className="i-a-block mr-t30">
                <CustomRadioButton
                  notRequired={!getValues(`is_co_signer.required`)}
                  onClick={() => {
                    if (watch("is_co_signer.fields")?.length > 0 && !getValues( `is_co_signer.fields`).find((item,index,arr)=>{
                      return item.is_fixed===true
                     })) {
                      setOver18(false);
                      setUnder18(false);
                      setCosinger(true);
                    }
                  }}
                  disable={watch("is_co_signer.fields")?.length > 0}
                  label={`${getValues(
                    `is_co_signer.labelIndex`
                  )}. Do you expect to need a guarantor/co-signer?`}
                  register={register("is_co_signer.response")}
                />
              </div>

              {watch("is_co_signer.response") === "Yes" && (
                <CoSigner
                  register={register}
                  checkSourceAvailableHandler={checkSourceAvailableHandler}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={over18 || under18 || coSinger}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setUnder18(false);
              setOver18(false);
              setCosinger(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              if (over18) {
                setUnder18(false);
                setOver18(false);
                setCosinger(false);
                setValue("anyone_over_18.fields", []);
                setValue("anyone_over_18.response", "No");
              }
              if (under18) {
                setOver18(false);
                setUnder18(false);
                setCosinger(false);
                setValue("co_occupants.fields", []);
                setValue("co_occupants.response", "No");
              }
              if (coSinger) {
                setOver18(false);
                setUnder18(false);
                setCosinger(false);
                setValue("is_co_signer.fields", []);
                setValue("is_co_signer.response", "No");
              }
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>

      {viewOnlyFlag || nextSection && (
        <Button
          onClick={() => {
            navigate("#contacts");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab
        </Button>
      )}
    </AccordionDetails>
  );
};

export default ApplicantDetail;
