/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import PaymentSignature from "./payment-signature/payment-signature";
import TermsCondition from "./terms-condition/terms-condition";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import InputCheckBoxField from "../../registration/Inputs/InputCheckBoxField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  AccordionDetails,
  Snackbar,
} from "@mui/material";
import { tabsStateAction } from "../../../store/identification/tabStateSlice";
import MuiAlert from "@mui/material/Alert";

import CloseIcon from "@mui/icons-material/Close";
import { DevTool } from "@hookform/devtools";
import {
  useGetSectionStatusQuery,
  useGetSubscriberFeesQuery,
  useGetTermandCondtionQuery,
} from "../../../services/identificationApi";
import { sideNavAction } from "../../../store/identification/sideMenuSlice";
import SupportSection from "../../common/SupportSection/SupportSection";
import parse from 'html-react-parser';
import { JSONTree } from "react-json-tree";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ConsentPayment = (props) => {
  const [callSubmit, setCallSubmit] = useState(false);
  const [subscriberFee, setSubscriberFee] = useState(0);
  const [applicantFee, setApplicantFee] = useState(0);
  const [clickHere, setClickHere] = React.useState(true);
  const formSectionSubmit = useSelector(
    (state) => state.formSubmit.fromSectionSubmit
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const submitBtnRef = useRef(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    document.title = "Consent & Payment";
  }, []);
  useEffect(() => {
    (async () => {
      if (formSectionSubmit) {
        if (!(await handleSubmitValidation("terms"))) return;
        if (!(await handleSubmitValidation("payment"))) return;
        submitBtnRef?.current?.click();
      }
    })();
  }, [formSectionSubmit]);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );

  const { data, error, isLoading } = useGetTermandCondtionQuery(trn_id)
  // .getDetails('${trn_id}').data
  const nj_status = useSelector((state) => state.identificationApi.queries);
  const sectionStatus = useGetSectionStatusQuery(trn_id);

  const termsStatus = useSelector(
    (state) => state.identification.tabsStateSlice.termsStatus
  );

  const paymentStatus = useSelector(
    (state) => state.identification.tabsStateSlice.paymentStatus
  );

  const paymentErr = useSelector(
    (state) => state.identification.tabsStateSlice.paymentErr
  );

  const getSubscriberFeesData = useGetSubscriberFeesQuery(trn_id);

  useEffect(() => {
    const fees_data = getSubscriberFeesData?.data;
    if (fees_data) {
      setSubscriberFee(fees_data?.records?.recordset[0]?.SubscriberFee);
      setApplicantFee(fees_data?.records?.recordset[0]?.ApplicationFee);
      dispatch(
        sideNavAction.enterApplicationFee(
          fees_data?.records?.recordset[0]?.ApplicationFee * 100
        )
      );
    }
  }, [getSubscriberFeesData?.data]);

  useEffect(() => {
    if (sectionStatus?.data?.consent_payment_status === true) {
      dispatch(tabsStateAction.Payment(true));
    }
  }, [dispatch, sectionStatus?.data]);

  const schema = yup.object().shape({
    billingAddress: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
    state: yup.object().typeError("Required").required("Required"),
    city: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
    zipCode: yup
      .string()
      .typeError("Required")
      // .matches(/^(\d{5})$/g, "Please enter valid zip code")
      .matches(
        /^(\d{4}[0-9]|\d{8}[0-9]|\d{4}[0-9]-\d{3}[0-9]|-----\d{3}[0-9])$/g,
        "Please enter valid zip code"
      )
      .required("Required"),
    cname: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
  });
// .matches(/^[A-Za-z\u00C0-\u017F'-]+$/, "Enter Valid Name")
  const methods = useForm({
    defaultValues: {
      city: "",
      state: "",
      zipCode: "",
      concent: false,
      copyapplicant: false,
      copybillingaddress: false,
      copyapplicantname: false,
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (fromData) => {
    console.log("fromData", fromData);
    setCallSubmit(true);
    setCallSubmit(false);
    // navigate("/success");
  };

  const handleSubmitValidation = async (parms) => {
    if (parms === "terms") {
      const localSchema = yup.object().shape({
        concent: yup.bool().oneOf([true], "This field must be checked"),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Terms(false));
          dispatch(tabsStateAction.PaymentErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "payment") {
      const localSchema = yup.object().shape({
        billingAddress: yup.string().required("Required"),
        state: yup.object().typeError("Required").required("Required"),
        zipCode: yup.string().typeError("Required").required("Required"),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Payment(false));
          dispatch(tabsStateAction.PaymentErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    }
  };

  const handleChange = async (parms) => {
    if (parms === "terms") {
      const localSchema = yup.object().shape({
        concent: yup.bool().oneOf([true], "This field must be checked"),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (result) {
          methods.clearErrors();
          dispatch(tabsStateAction.Terms(true));
          dispatch(tabsStateAction.PaymentErr(false));
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Terms(false));
          dispatch(tabsStateAction.PaymentErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    }
  };
  const navigate = useNavigate();
  const { hash } = useLocation();
  useEffect(() => {
    if (!hash) return;
    if (hash == "#tc") {
      navigate(-1);
      return dispatch(tabsStateAction.Terms(false));
    }
    if (hash == "#ps") {
      navigate(-1);
      return handleChange("terms");
    }
  }, [hash]);
  if (isLoading) return <p>Loading...</p>
  return (
    <div id="con-pay-tabs">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={!termsStatus}
            onChange={() => {
              dispatch(tabsStateAction.Terms(false));
            }}
          >
            <AccordionSummary
              expandIcon={termsStatus && <AiOutlinePlus />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                {termsStatus && <BsCheck2Circle color="green" />} Terms and
                Conditions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="static-info">
                {data?.records?.text && (<>
                  {parse(data?.records?.text)}
                </>)}

                {data?.records?.subscriber_text && (<>
                  <br />
                  {parse(data?.records?.subscriber_text)}
                </>)}

                <div className="tc-check mr-t60">
                  <div className="row">
                    <div className="col-sm-8 col-md-8 col-lg-8 mx-auto">
                      <div className="cus-check red-check mr-b15 mr-t10">
                        <input
                          type="checkbox"
                          name="concent"
                          {...methods.register(`concent`)}
                          id="concent"
                        />
                        <label
                          htmlFor="concent"
                          className="mr-b0 d-flex align-items-center"
                        >
                          <span className="mr-r10"></span>{" "}
                          <p>
                            I have read, understood, and consent to
                            Docuverus's{" "}
                            <a
                              href="http://40.71.253.227/DataPolicy/Index"
                              target="_blank"
                              rel="noreferrer"
                              className={`${!methods.watch(`concent`)
                                ? "red"
                                : "app-blue-color"
                                }`}
                            >
                              Data Policy{" "}
                            </a>
                            and{" "}
                            <a
                              href="http://40.71.253.227/TermsOfUse/Index"
                              target="_blank"
                              rel="noreferrer"
                              className={`${!methods.watch(`concent`)
                                ? "red"
                                : "app-blue-color"
                                }`}
                            >
                              Terms of Use.
                            </a>{" "}
                            I also authorize Docuverus, LLC to charge my
                            credit or debit card the NON-REFUNDABLE
                            Application Fee of {"$" + applicantFee}.00
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={!methods.watch("concent")}
            expanded={!paymentStatus && methods.watch("concent")}
            onChange={() => {
              handleChange("terms");
            }}
          >
            <AccordionSummary
              expandIcon={paymentStatus && <AiOutlinePlus />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                {paymentStatus && <BsCheck2Circle color="green" />} Payment &
                Signature
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentSignature
                data={data}
                callSubmit={callSubmit}
                applicationFee={applicantFee}
              />
            </AccordionDetails>
          </Accordion>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={paymentErr}
            key={1}
          >
            <Alert
              onClick={() => {
                dispatch(tabsStateAction.PaymentErr(false));
              }}
              severity="error"
            >
              {" "}
              Answer the required field <CloseIcon />
            </Alert>
          </Snackbar>

          <button
            ref={submitBtnRef}
            style={{ display: "none" }}
            type="submit"
            className="btn btn-info"
          >
            Next
          </button>
        </form>
      </FormProvider>
      <DevTool control={methods.control} />
      <div className="mt-4">
        <SupportSection clickHere={clickHere} setClickHere={setClickHere} />
      </div>
    </div>
  );
};

export default ConsentPayment;
