// import { ErrorMessage } from "@hookform/error-message";
// import {
//   AccordionDetails,
//   Alert,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   LinearProgress,
//   Stack,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import {
//   set,
//   useFieldArray,
//   useFormContext,
//   useFormState,
// } from "react-hook-form";
// import { useSelector } from "react-redux";
// import {
//   useAddEmployerSourceMutation,
//   useDeleteSourceMutation,
// } from "../../../../services/identificationApi";
// import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
// import Source from "./source";
// import { AiOutlineCheckCircle } from "react-icons/ai";
// import Loader from "react-loader-spinner";
// import debounce from "lodash.debounce";
// import { useNavigate} from "react-router-dom";
// import { AiOutlineArrowDown } from "react-icons/ai";
// import { Button } from "@mui/material";
// import { JSONTree } from "react-json-tree";

// const BankingInformation = (props) => {
//   const trn_id = useSelector(
//     (state) => state.persistedReducer.loginSlice.trnId
//   );
//   const useWindowWidth = useSelector(
//     (state) => state.identification.sideMenuSlice
//   );
  
//   const [doDelete, deleteResult] = useDeleteSourceMutation();
//   const [disabledSouceBtn, setDisabledSourceBtn] = useState(false);
//   const [deleteBankingInformation, setDeleteBankingInformation] =
//     React.useState(false);
//     const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const { control, register, watch, getValues, setValue } = useFormContext();
//   const [addEmployerSource, employerAddResult] = useAddEmployerSourceMutation();
//   const { fields, append, remove } = useFieldArray({
//     control, // control props comes from useForm (optional: if you are using FormContext)
//     name: "banking_details.banking", // unique name for your Field Array
//     keyName: "controlId", //default to "id", you can change the key name
//   });
//   const { errors } = useFormState({
//     control,
//   });
//   console.log("Check Fields for IB:28", fields);
//   //&& getValues(`banking_details.banking`)?.length
//   const deleteAllBankingSource = async () => {
//     let promises = [];
//     let allBankingSource = await getValues(`banking_details.banking`);
//     if (allBankingSource?.length > 0) {
//       for (let index = 0; index < allBankingSource.length; index++) {
//         const element = allBankingSource[index];
//         promises.push(doDelete({
//           trn_id: trn_id,
//           source_id: index + 1,
//           document_id: "6",
//         }))

//       }
//       Promise.all(promises).then(response => {
//         const allbanksource=watch('banking_details.banking');
//         if(allbanksource?.length === 0)
//         {
//           setDeleteBankingInformation(false);
//           setValue(`banking_details.response`, "No");
//         }
        
//       });

//     }

   
//    };
//   const [nextSection, setNextSection] = React.useState(false);
//   const navigate = useNavigate();
//   // highlight-starts
//   const debouncedSave = React.useCallback(
//     debounce(
//       (nextValue) =>
//         (async () => {
//           setNextSection(await props.checkValidation("Bank", false));
//         })(),
//       1000
//     ),
//     [] // will be created only once initially
//   );
//   // highlight-ends
//   const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
//   React.useEffect(() => {
//     const subscription = watch((value, { name, type }) => {
//       console.log("IB:32", value, name, type);
//       debouncedSave(name);
//       if (type === "change" && name === `banking_details.response`) {
//         if (
//           getValues(`banking_details.response`) === "Yes" &&
//           getValues(`banking_details.banking`)?.length === 0
//         ) {
//           addEmployerSource({
//             trn_id: trn_id,
//             document_id: "6",
//             source_id: 1,
//           });
//         }
//       }
//     });
//     return () => subscription.unsubscribe();
//   }, [addEmployerSource, fields?.length, getValues, trn_id, watch]);

//   useEffect(() => {
//     setDisabledSourceBtn(false);
//   }, [fields?.length]);
 
//   return (
//     <AccordionDetails>
//       <div className="bank-info-body">
//         <div className="row">
//           <div className="col-sm-12 col-md-12 col-lg-6 mr-t30">
//             <JSONTree data={watch("banking_details.employerCount")}/>
//             <CustomRadioButton
//               onClick={(e) => {
//                 // e.preventDefault();
//                 if (watch("banking_details.employerCount") > 0) {
//                   //  setDeleteBankingInformation(true);
//                   setOpen(true);
//                   // alert("hi")
//                 }
//               }}
//             //  disabled={true}
//               // disable={getValues(`banking_details.employerCount`)}
//               register={register(`banking_details.response`)}
//               label="Do you have a Bank Account ? "
//             />
//           </div>
//           {watch(`banking_details.response`) === "Yes" && (
//             <>
//               {fields.map((field, index) => (
//                 <Source
//                   key={field.controlId}
//                   remove={remove}
//                   field={field}
//                   index={index}
//                 />
//               ))}
//               {fields?.length <= 2 && (
//                 <div className="col-sm-12">
//                   <div className="add-text-btn flex1 text-center mr-t40">
//                     {employerAddResult.isLoading ? (
//                       <>
//                         <Box sx={{ width: "100%" }}>
//                           <LinearProgress />
//                         </Box>
//                       </>
//                     ) : (
//                       <>
//                         {fields?.length >= 1 && <button
//                           onClick={(event) => {
//                             setDisabledSourceBtn(true);
//                             addEmployerSource({
//                               trn_id: trn_id,
//                               document_id: "6",
//                               source_id: fields.length + 1,
//                             })
//                           }}
//                           type="button"
//                           disabled={disabledSouceBtn}
//                         >
//                           <svg
//                             width="13"
//                             height="13"
//                             viewBox="0 0 13 13"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
//                               fill="#2DC3E8"
//                             ></path>
//                           </svg>
//                           Add another Bank Account
//                         </button>}
//                       </>
//                     )}
//                   </div>
//                 </div>
//               )}
//             </>
//           )}
//           <Stack
//             sx={{ width: "100%" }}
//             style={{ textTransform: "capitalize", marginTop: "10px" }}
//             spacing={2}
//           >
//             <ErrorMessage
//               errors={errors}
//               name="banking_details.banking"
//               render={({ message }) => {
//                 if (message) return <Alert severity="error">{message}</Alert>;

//                 return <></>;
//               }}
//             />
//           </Stack>
//         </div>
//       </div>
//       {/* <Dialog
//         open={deleteBankingInformation}
//         onClose={() => {
//           setDeleteBankingInformation(false);
//         }}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {`Are you sure want to delete45
//            ?`}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Warning! Once deleted cannot revert back .
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <button
//             className="btn btn-info text-light"
//             onClick={() => {
//               setDeleteBankingInformation(false);
//             }}
//           >
//             No
//           </button>
//           <button
//             className="btn btn-info text-light"
//             disabled={deleteResult.isLoading}
//             onClick={() => {
//               deleteAllBankingSource();
//             }}

//           >
//             {deleteResult.isLoading ? (
//               <Loader
//                 type="Circles"
//                 color="#FFFFFF"
//                 height={20}
//                 width={20}
//                 radius={20}
//                 timeout={3000}
//               />
//             ) : (
//               "Yes"
//             )}
//           </button>
//         </DialogActions>
//       </Dialog> */}
//        <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Warning!"}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             please delete sources first
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>
      
//       {viewOnlyFlag || nextSection && (
//         <Button
//           onClick={() => {
//             navigate("#od");
//           }}
//           variant="contained"
//           endIcon={<AiOutlineArrowDown />}
//         >
//           Next Tab
//         </Button>
//       )}
//     </AccordionDetails>
//   );
// };

// export default BankingInformation;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Source from "./source";
import { useAddEmployerSourceMutation, useDeleteSourceMutation } from "../../../../services/identificationApi";
import { Dialog, DialogActions, DialogContent, AccordionDetails,DialogContentText,Box, DialogTitle, Button, LinearProgress } from "@mui/material";
import { useFormContext, useFieldArray, useFormState } from "react-hook-form";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";

const BankingInformation = (props) => {
  const trn_id = useSelector(state => state.persistedReducer.loginSlice.trnId);
  const [doDelete, deleteResult] = useDeleteSourceMutation();
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false); // New state for tracking confirmation

  const { control, register, watch, getValues, setValue } = useFormContext();
  const [addEmployerSource, employerAddResult] = useAddEmployerSourceMutation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "banking_details.banking",
    keyName: "controlId",
  });
  const { errors } = useFormState({ control });

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteAllBankingSource = async () => {
    let promises = [];
    let allBankingSource = await getValues(`banking_details.banking`);
    if (allBankingSource?.length > 0) {
      for (let index = 0; index < allBankingSource.length; index++) {
        const element = allBankingSource[index];
        promises.push(doDelete({
          trn_id: trn_id,
          source_id: index + 1,
          document_id: "6",
        }));
      }
      Promise.all(promises).then(response => {
        const allbanksource = watch('banking_details.banking');
        if (allbanksource?.length === 0) {
          setConfirmDelete(false);
          setValue(`banking_details.response`, "No");
        }
      });
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name === `banking_details.response`) {
        if (
          getValues(`banking_details.response`) === "Yes" &&
          getValues(`banking_details.banking`)?.length === 0
        ) {
          addEmployerSource({
            trn_id: trn_id,
            document_id: "6",
            source_id: 1,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [addEmployerSource, fields?.length, getValues, trn_id, watch]);

  return (
    <AccordionDetails>
      <div className="bank-info-body">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 mr-t30">
            <CustomRadioButton
              onClick={(e) => {
                if (watch("banking_details.employerCount") > 0) {
                  setOpen(true);
                }
              }}
              register={register(`banking_details.response`)}
              label="Do you have a Bank Account ? "
            />
          </div>
          {watch(`banking_details.response`) === "Yes" && (
            <>
              {fields.map((field, index) => (
                <Source
                  key={field.controlId}
                  remove={remove}
                  field={field}
                  index={index}
                />
              ))}
              {fields?.length <= 2 && (
                <div className="col-sm-12">
                  <div className="add-text-btn flex1 text-center mr-t40">
                    {employerAddResult.isLoading ? (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <button
                        onClick={() => {
                          addEmployerSource({
                            trn_id: trn_id,
                            document_id: "6",
                            source_id: fields.length + 1,
                          });
                        }}
                        type="button"
                      >
                        Add another Bank Account
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Dialog for warning */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You must delete all existing sources first.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValue(`banking_details.response`, "Yes");
              setConfirmDelete(false); // Ensures response does not change
              handleClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {watch("banking_details.response") === "Yes" && (
        <Button
          onClick={() => {
            navigate("#od");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab
        </Button>
      )}
    </AccordionDetails>
  );
};

export default BankingInformation;

