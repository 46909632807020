import React from "react";
import { useFormContext, useController } from "react-hook-form";

const InputField = (props) => {
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { name } = props.register;
  const { control, setValue, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const convertSSNFormat = () => {
    let val = field.value;
    val = val.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    setValue("ssn_data.SSNNumber", val);
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`form-label ${error !== undefined && "text-danger"}`}
      >
        {props.label} {required && <span className="red">*</span>}
      </label>

      <input
        minLength={props?.minLength ? props.minLength : ""}
        maxLength={props?.maxLength ? props.maxLength : ""}
        type={props?.type ? props.type : "text"}
        disabled={props.disable === true ?? "disabled"}
        placeholder={props.placeholder || `${props.label}`}
        className={`form-control cus-input-control ${
          required && !field.value && "is-warning"
        } ${error !== undefined && "is-invalid"}`}
        {...field}
        value={field.value||''}
        onBlur={(e) => {
          const dependentFun = props.triggerDependent || null;
          if (dependentFun) {
            if (!e.target.value == "") {
              dependentFun(e);
            }
          }
          field.onBlur(e);
          if (props.inputType === "SSN") {
            if (field?.value?.length === 9) {
              convertSSNFormat();
            }
          }
        }}
        id={name}
      />
      <div className="invalid-feedback">
        {error !== undefined && error.message}
      </div>
    </>
  );
};

export default InputField;
