import React, { useEffect, useState } from 'react';
import DBR from "../../dbr";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
const DLAbbrDesMap = {
    'DCA': 'Jurisdiction-specific vehicle class',
    'DBA': 'Expiry Date',
    'DCS': 'Last Name',
    'DAC': 'First Name',
    'DBD': 'Issue Date',
    'DBB': 'Birth Date',
    'DBC': 'Gender',
    'DAY': 'Eye Color',
    'DAU': 'Height',
    'DAG': 'Street',
    'DAI': 'City',
    'DAJ': 'State',
    'DAK': 'Zip',
    'DAQ': 'License Number',
    'DCF': 'Document Discriminator',
    'DCG': 'Issue Country',
    'DAH': 'Street 2',
    'DAZ': 'Hair Color',
    'DCI': 'Place of birth',
    'DCJ': 'Audit information',
    'DCK': 'Inventory Control Number',
    'DBN': 'Alias / AKA Family Name',
    'DBG': 'Alias / AKA Given Name',
    'DBS': 'Alias / AKA Suffix Name',
    'DCU': 'Name Suffix',
    'DCE': 'Physical Description Weight Range',
    'DCL': 'Race / Ethnicity',
    'DCM': 'Standard vehicle classification',
    'DCN': 'Standard endorsement code',
    'DCO': 'Standard restriction code',
    'DCP': 'Jurisdiction-specific vehicle classification description',
    'DCQ': 'Jurisdiction-specific endorsement code description',
    'DCR': 'Jurisdiction-specific restriction code description',
    'DDA': 'Compliance Type',
    'DDB': 'Card Revision Date',
    'DDC': 'HazMat Endorsement Expiration Date',
    'DDD': 'Limited Duration Document Indicator',
    'DAW': 'Weight(pounds)',
    'DAX': 'Weight(kilograms)',
    'DDH': 'Under 18 Until',
    'DDI': 'Under 19 Until',
    'DDJ': 'Under 21 Until',
    'DDK': 'Organ Donor Indicator',
    'DDL': 'Veteran Indicator',
    // old standard
    'DAA': 'Customer Full Name',
    'DAB': 'Customer Last Name',
    'DAE': 'Name Suffix',
    'DAF': 'Name Prefix',
    'DAL': 'Residence Street Address1',
    'DAM': 'Residence Street Address2',
    'DAN': 'Residence City',
    'DAO': 'Residence Jurisdiction Code',
    'DAR': 'License Classification Code',
    'DAS': 'License Restriction Code',
    'DAT': 'License Endorsements Code',
    'DAV': 'Height in CM',
    'DBE': 'Issue Timestamp',
    'DBF': 'Number of Duplicates',
    'DBH': 'Organ Donor',
    'DBI': 'Non-Resident Indicator',
    'DBJ': 'Unique Customer Identifier',
    'DBK': 'Social Security Number',
    'DBM': 'Social Security Number',
    'DCH': 'Federal Commercial Vehicle Codes',
    'DBR': 'Name Suffix',
    'PAA': 'Permit Classification Code',
    'PAB': 'Permit Expiration Date',
    'PAC': 'Permit Identifier',
    'PAD': 'Permit IssueDate',
    'PAE': 'Permit Restriction Code',
    'PAF': 'Permit Endorsement Code',
    'ZVA': 'Court Restriction Code',
    'DAD': 'Middle Name'
};

export const parseDriverLicense = txt => {
    let lines = txt.split('\n');
    let abbrs = Object.keys(DLAbbrDesMap);
    let map = {};
    lines.forEach((line, i) => {
        let abbr;
        let content;
        if (i === 1) {
            abbr = 'DAQ';
            content = line.substring(line.indexOf(abbr) + 3);
        } else {
            abbr = line.substring(0, 3);
            content = line.substring(3).trim();
        }
        if (abbrs.includes(abbr)) {
            map[abbr] = {
                description: DLAbbrDesMap[abbr],
                content: content
            };
        }
    });
    return map;
};

const BackBarcodeScan = () => {
    const [pscannerS, setPscannerS] = useState(null);
    let bDestroyed = false;
    let pScanner = null;
    let elRef = React.createRef();
    const navigate = useNavigate();
    let { id, docId } = useParams();
    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            window.history.go(1);
        };
    }, [])
    useEffect(() => {
        (async () => {
            try {
                let scanner = await (pScanner = pScanner || DBR.BarcodeScanner.createInstance());
                let settings = await scanner.getRuntimeSettings();
                settings.region.regionMeasuredByPercentage = 1;
                settings.region.regionLeft = 5;
                settings.region.regionTop = 25;
                settings.region.regionRight = 95;
                settings.region.regionBottom = 65;
                await scanner.updateRuntimeSettings(settings);
                scanner.onFrameRead = results => {
                };
                scanner.onUnduplicatedRead = (txt, result) => {
                    if (txt.length > 0) {
                        navigate(`/scan/${id}/camera_back/${docId}?raw=${encodeURIComponent(txt)}`, { replace: true });
                    }
                };

                let runtimeSettings = await scanner.getRuntimeSettings();
                runtimeSettings.barcodeFormatIds = DBR.EnumBarcodeFormat.BF_PDF417;
                runtimeSettings.LocalizationModes = [2, 8, 0, 0, 0, 0, 0, 0];
                runtimeSettings.deblurLevel = 2;
                await scanner.updateRuntimeSettings(runtimeSettings);

                elRef.current.appendChild(scanner.getUIElement());
                setPscannerS(pScanner);
                await scanner.open();

            } catch (ex) {
                console.error(ex);
            }
        })();
        return () => {
            (async () => {
                bDestroyed = true;
                if (pScanner) {
                    (await pScanner).destroy();
                }
            })()
        }
    }, [id])




    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar sx={{ backgroundColor: '#2dc3e8' }} position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Docuverus | Scan Barcode
                        </Typography>
                        <Button color="inherit" onClick={() => {
                            navigate(`/scan/${id}/camera_back/${docId}}`, { replace: true });
                        }}>Skip</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <br />
            <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Align the barcode in the viewport and hold your device still. The barcode will then scan automatically.</p>

            <div className='dbr-scan' style={{ width: '80wh', height: '80vh', background: "#fff" }} ref={elRef}>
             
            </div>
            <br />

        </div>
    );
};

export default BackBarcodeScan;