import React, { useEffect, useState } from "react";
import { yupResolver, yupArray } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "../../components/registration/Inputs/InputField";
import { FormProvider, useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import { useForgotUserMutation } from "../../services/registrationApi";

const ForgotPassword = (props) => {

    const [forgotUser, forgotUserResult] = useForgotUserMutation()

    const schema = yup.object().shape({
        email: yup.string().email().required("Required")
    });

    const methods = useForm({
        defaultValues: {
            email: "",
        },
        resolver: yupResolver(schema),
        mode: "onBlur",
    });

    const onSubmit = (data) => {
        forgotUser(data).then(
            (response) => {
                console.log(response);
                if (response?.data && response.data.status == 200) {
                    toast.success(response.data.message, { pauseOnFocusLoss: false });
                    props.emit(true);
                } else {
                    toast.error(response.error.data.message, { pauseOnFocusLoss: false });
                }

            }
        ).catch(
            (error) => {
                toast.error(error.message, { pauseOnFocusLoss: false });
            }
        )
    };


    return (
        <div className="modal-dialog forgot-modal" style={{ maxWidth: '390px' }}>

            <div className="modal-content">

                <div className="modal-body sign-m-body" >
                    <button type="button" className="close m-close-icon" data-dismiss="modal" aria-label="Close">
                        <img src="images/close-btn.png" alt="" />
                    </button>
                    <div className="sign-title text-center mr-b50">
                        <h4>Forgot Password</h4>
                    </div>
                    <div className="auth-form mr-t30">
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>

                            <div className="form-group cus-in-group mr-b20">

                                <InputField
                                    label="Email"
                                    register={ methods.register("email")}
                                />

                            </div>
                            <button type="submit" className="l-btn">
                                Send Reset Link
                            </button>

                        </form>
        </FormProvider>
                    </div>

                </div>

            </div>
            <ToastContainer />
        </div>
        // </div>

    );
};

export default ForgotPassword;
