import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CgComment } from "react-icons/cg";
import UploadFile from "../../../common/uploadFile/uploadFile";
import FileIcon from "../../../common/FileIcon";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import InputDateField from "../../../registration/Inputs/InputDateField";
import InputField from "../../../registration/Inputs/InputField";
import InputFieldPhone from "../../../registration/Inputs/InputFieldPhone";
import { TiDelete } from "react-icons/ti";
import { useDeleteSourceMutation } from "../../../../services/identificationApi";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Stack } from "@mui/material";
// import { makeStyles} from "@mui/styles"
import NumberFormat from "react-number-format";
import InputCurrencyField from "../../../registration/Inputs/InputCurrencyField";
import SelectField from "../../../registration/Inputs/SelectField";
import { useGetMasterRegistrationDataQuery } from "../../../../services/registrationApi";
import InputTextArea from "../../../registration/Inputs/InputTextArea";
import InputMaskField from "../../../registration/Inputs/InputMaskFieid";
import GoogleAutoComplete from "../../../common/googleAutocomplete/googleAutocomplete";
import { RiInformationLine } from "react-icons/ri";
import RadioInputField from "../../../registration/Inputs/RadioInputField";
import { ErrorMessage } from "@hookform/error-message";
import InputCheckBoxField from "../../../registration/Inputs/InputCheckBoxField";

const Source = ({ field, index }) => {
  // const classes = useStyles();
  const [employmentIncomeProof, setEmploymentIncomeProof] = useState(false);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const { data } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(false);
  const [displayContent, setDisplayContent] = useState(0);
  const [doDelete, deleteResult] = useDeleteSourceMutation();

  const {
    register,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        (name == `employment_details.employer[${index}].pay_frequency` ||
          name == `employment_details.employer.${index}.avrgross_pay`)
      ) {
        let _monthlyIncome =
          getValues(`employment_details.employer.${index}.avrgross_pay`) || 0;
        const pay_frequency = getValues(
          `employment_details.employer[${index}].pay_frequency`
        ).value;
        if (
          watch(`employment_details.employer[${index}].pay_frequency`) !== ""
        ) {
          if (pay_frequency == "Weekly") {
            _monthlyIncome = (_monthlyIncome * 52) / 12;
          } else if (pay_frequency == "Bi-Weekly") {
            _monthlyIncome = (_monthlyIncome * 26) / 12;
          } else if (pay_frequency == "Monthly") {
            //_monthlyIncome = _monthlyIncome;
          } else if (pay_frequency == "Bi-Monthly") {
            _monthlyIncome = (_monthlyIncome * 24) / 12;
          } else if (pay_frequency == "One-Time") {
            _monthlyIncome = _monthlyIncome / 12;
          } else {
            _monthlyIncome = _monthlyIncome / 12;
          }
          setValue(
            `employment_details.employer.${index}.total_income`,
            _monthlyIncome || 0
          );
          setValue(
            `employment_details.employer.${index}.total_annual_income`,
            (_monthlyIncome || 0) * 12
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, index, setValue, watch]);

  // useEffect(() => {
  //   const subscribe = watch((value, { name, type }) => {
  //     if (
  //       type == "change" &&
  //       name == `employment_details.employer.${index}.iscurrent_employer`
  //     ) {
  //       setValue(
  //         `employment_details.employer.${index}.employment_end_date`,
  //         null
  //       );
  //       trigger(`employment_details.employer.${index}.employment_end_date`);
  //     }
  //     if (
  //       type == "change" &&
  //       name == `employment_details.employer.${index}.isfuture_employer`
  //     ) {
  //       setValue(
  //         `employment_details.employer.${index}.employment_end_date`,
  //         ""
  //       );
  //       trigger(`employment_details.employer.${index}.employment_end_date`);
  //     }
  //   });
  //   return () => subscribe.unsubscribe();
  // }, [watch, setValue, index, trigger]);

  return (
    <>
      <div key={field.controlId} className="col-sm-12">
        <br />

        <div className="blue-box pd15">
          <div className="row">
            <div className="d-flex justify-content-end mb-2"></div>
            <div className="col-sm-12">
              <span className="s-no-index float-left">{index + 1}</span>{" "}
              {deleteResult.isUninitialized ? (
                <button
                  type="button"
                  onClick={async () => {
                    setOpen(true);
                  }}
                  title="Delete this Employer"
                  className="float-right btn btn-sm btn-danger"
                >
                  <TiDelete size={25} /> Delete
                </button>
              ) : (
                <button disabled className="float-right btn btn-sm btn-danger">
                  <TiDelete size={25} /> Deleting...
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputField
                  label="Employer Name"
                  register={register(
                    `employment_details.employer[${index}].employer_name`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputField
                  label="Position"
                  register={register(
                    `employment_details.employer.${index}.position`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputFieldPhone
                  label={`Employer Phone Number`}
                  register={register(
                    `employment_details.employer.${index}.phone_no`
                  )}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="form-group cus-input-group mr-b20">
                <GoogleAutoComplete
                  notRequired={true}
                  groupFieldName={{
                    street: `employment_details.employer.${index}.employer_address`,
                    city: `employment_details.employer.${index}.employer_city`,
                    state: `employment_details.employer.${index}.employer_state`,
                    zip: `employment_details.employer.${index}.employer_zipcode`,
                    state_data: data?.records?.States,
                  }}
                  register={register(
                    `employment_details.employer.${index}.search_employer_address`
                  )}
                  label="Search Employer Address"
                />
                {/* <InputField
                  label="Address"
                  register={register(
                    `employment_details.employer.${index}.employer_address`
                  )}
                /> */}
              </div>
            </div>

            <div className="w-100"></div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputField
                  label="Employer Address"
                  register={register(
                    `employment_details.employer.${index}.employer_address`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputField
                  label="City"
                  register={register(
                    `employment_details.employer.${index}.employer_city`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <SelectField
                  label="State"
                  options={data?.records?.States}
                  register={register(
                    `employment_details.employer.${index}.employer_state`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputField
                  maxLength={10}
                  minLength={5}
                  label="Zip"
                  register={register(
                    `employment_details.employer.${index}.employer_zipcode`
                  )}
                />
                {/* <InputMaskField
                  maxLength={5}
                  inputType="ZIP"
                  label="Zip Code"
                  register={register(
                    `employment_details.employer.${index}.employer_zipcode`
                  )}
                  mask={"99999"}
                /> */}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputField
                  label="Manager Name"
                  register={register(
                    `employment_details.employer.${index}.manager_name`
                  )}
                />
              </div>
            </div>
            <br />
            <div className="row">
              {watch(
                `employment_details.employer.${index}.isfuture_employer`
              ) == false && (
                <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-between align-items-center">
                  <InputCheckBoxField
                    notRequired={true}
                    // disabled={index == 0 ? true : false}
                    checked={watch(
                      `employment_details.employer.${index}.iscurrent_employer`
                    )}
                    label="Is this current employer?"
                    register={register(
                      `employment_details.employer.${index}.iscurrent_employer`
                    )}
                  />
                </div>
              )}
              {watch(
                `employment_details.employer.${index}.iscurrent_employer`
              ) == false && (
                <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-between align-items-center">
                  <InputCheckBoxField
                    notRequired={true}
                    // disabled={index == 0 ? true : false}
                    checked={watch(
                      `employment_details.employer.${index}.isfuture_employer`
                    )}
                    label="Is this future employer?"
                    register={register(
                      `employment_details.employer.${index}.isfuture_employer`
                    )}
                  />
                </div>
              )}
            </div>
            <br />
            <br />
            <div className="w-100"></div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group cus-input-group mr-b20">
                  <SelectField
                    options={[
                      {
                        label: "Employed Full-Time",
                        value: "Employed Full-Time",
                      },
                      {
                        label: "Employed Part-Time",
                        value: "Employed Part-Time",
                      },
                    ]}
                    label="Employment Status"
                    register={register(
                      `employment_details.employer[${index}].employer_status`
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group cus-input-group mr-b20">
                  <InputDateField
                    label="Employment Start Date"
                    placeholder="Enter Date"
                    type={"date"}
                    register={register(
                      `employment_details.employer.${index}.employment_start_date`
                    )}
                  />
                </div>
              </div>
              {watch(
                `employment_details.employer.${index}.iscurrent_employer`
              ) === false &&
                watch(
                  `employment_details.employer.${index}.isfuture_employer`
                ) === false && (
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group cus-input-group mr-b20">
                      <InputDateField
                        notRequired={false}
                        label="Employment End Date *"
                        placeholder="Enter Date"
                        register={register(
                          `employment_details.employer.${index}.employment_end_date`
                        )}
                      />
                    </div>
                  </div>
                )}
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputCurrencyField
                  label="Gross Pay (Pre Tax)"
                  register={register(
                    `employment_details.employer.${index}.avrgross_pay`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <InputCurrencyField 
                  notRequired={true}
                  disabled={true}
                  label="Document Gross Pay (Pre Tax)"
                  register={register(
                    `employment_details.employer.${index}.monthly_avr_gross`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="form-group cus-input-group mr-b20">
                <SelectField
                  options={[
                    { label: "Weekly", value: "Weekly" },
                    { label: "Bi-Weekly", value: "Bi-Weekly" },
                    { label: "Monthly", value: "Monthly" },
                    { label: "Bi-Monthly", value: "Bi-Monthly" },
                    { label: "One-Time", value: "One-Time" },
                    { label: "Yearly", value: "Yearly" },
                  ]}
                  label="Pay Frequency (of the amt entered)"
                  register={register(
                    `employment_details.employer[${index}].pay_frequency`
                  )}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="incom-info-block">
                <h4>
                  {/* <span style={{ marginTop: "-6px", marginBottom: "-7px" }}>
                    Calculated Amounts:
                  </span> */}
                   Calculated Amounts:
                </h4>
                <div className="row">
                  <div className="col-8 small">Yearly Gross Income:</div>
                  <div className="col">
                    <NumberFormat
                      decimalScale={2}
                      value={watch(
                        `employment_details.employer.${index}.total_annual_income`
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 small"> Monthly Gross Income:</div>
                  <div className="col">
                    <NumberFormat
                      decimalScale={2}
                      value={watch(
                        `employment_details.employer.${index}.total_income`
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </div>
                </div>
                <br/>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="add-text-btn flex1 text-left  mr-b30">
                {getValues(`employment_details.employer.${index}.comment`) ===
                null ? (
                  <button type="button" onClick={() => setComment(true)}>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                        fill="#2DC3E8"
                      ></path>
                    </svg>
                    Add Comments <small>(if any)</small>
                  </button>
                ) : (
                  <>
                    <CgComment
                      onClick={() => {
                        setComment(true);
                      }}
                      size={25}
                      color={"#2dc3e8"}
                      className="cursor-pointer"
                    />{" "}
                    {watch(`employment_details.employer.${index}.comment`)}
                  </>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7">
              <CustomRadioButton
                disable={getValues(
                  `employment_details.employer.${index}.fileCount`
                )}
                register={register(
                  `employment_details.employer.${index}.availableToUpload`
                )}
                label="Do you have a Proof of Employment available to upload ?"
              />
            </div>
            {watch(`employment_details.employer.${index}.availableToUpload`) ==
            "Yes" ? (
              <>
                <div className="row">
                  <div className="ssn-text-block">
                    <h4>
                      Proof of Employment Income:
                      <span className="red">*</span>
                      {/* <button type="button" className="info-btn">
                          <img src="images/info-icon.png" alt="" />
                        </button> */}
                      <RiInformationLine
                        className="ms-2 cursor-pointer"
                        fill="#D3AF51"
                        size={20}
                        onClick={() => setEmploymentIncomeProof(true)}
                      />
                    </h4>
                    <h5>(covering 4 most recent weeks)</h5>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-9">
                    <small
                      style={{
                        background: "yellow",
                        color: "black",
                        fontSize: "12px",
                        lineHeight: "normal",
                        fontWeight: "600",
                      }}
                    >
                      IMPORTANT! PDF Earnings Statements downloaded directly
                      from payroll website ONLY! Please do NOT upload pictures
                      of paystubs. Click "i" button above for full details.
                    </small>
                    <div className="mt-4 mb-2">
                      <div className="mt-2">
                        <InputCheckBoxField
                          notRequired={true}
                          // disabled={index == 0 ? true : false}
                          checked={watch(
                            `employment_details.employer.${index}.allowOfferLetter`
                          )}
                          label=""
                          register={register(
                            `employment_details.employer.${index}.allowOfferLetter`
                          )}
                        />
                      </div>
                      <small
                        style={{
                          color: "black",
                          fontSize: "12px",
                          marginLeft: "25px",
                          lineHeight: "normal",
                          fontWeight: "600",
                        }}
                      >
                        <strong style={{ fontWeight: "bold" }}>
                          Newly employed?
                        </strong>{" "}
                        If you are starting a new job and do not yet have access
                        to original PDFs of your earnings statements, then
                        please check the box to the left, which will allow you
                        to upload a PDF of your offer letter.{" "}
                      </small>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center">
                    <div className="ssn-btn ms-2">
                      <UploadFile
                        allowOfferLetter={watch(
                          `employment_details.employer.${index}.allowOfferLetter`
                        )}
                        onlyPdf={true}
                        label="Upload Back"
                        className="mr-b10 "
                        documentId={3}
                        SourceId={
                          getValues(
                            `employment_details.employer.${index}.id`
                          ) || 0
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={` ${
                      useWindowWidth.windowWidth < 986 &&
                      `justify-content-center`
                    } d-flex flex-wrap`}
                  >
                    {watch(`employment_details.employer.${index}.files`)?.map(
                      (item, index) => {
                        return (
                          // <div
                          //   key={item.application_document_id}
                          //   className="col-sm-6 col-md-2 text-center"
                          // >
                          <FileIcon
                            key={item.application_document_id}
                            fileObj={item}
                          />
                          // </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </>
            ) : null}

            {watch(`employment_details.employer.${index}.availableToUpload`) ==
              "No" && (
              <>
                <RadioInputField
                  options={[
                    {
                      value:
                        "I don't currently have access to document(s), but can provide later",
                    },
                    {
                      value: "I do not have proof of employment",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                  name={`employment_details.employer.${index}.no_doc_reason`}
                />
                {watch(`employment_details.employer.${index}.no_doc_reason`) ===
                  "Other" && (
                  <div>
                    <InputField
                      label="Please Explain"
                      register={register(
                        `employment_details.employer.${index}.other_reason`
                      )}
                      placeholder="Please Explain"
                    />
                  </div>
                )}
              </>
            )}
            {/* {watch(`employment_details.employer.${index}.no_doc_reason`) ===
              "Other" &&
              watch(`employment_details.employer.${index}.availableToUpload`) ==
                "No" && (
                <div >
                  <InputField
                    label="Please Explain"
                    register={register(
                      `employment_details.employer.${index}.other_reason`
                    )}
                    placeholder="Please Explain"
                  />
                </div>
              )} */}
          </div>
        </div>
      </div>
      <Stack sx={{ width: "100%" }} style={{ marginTop: "10px" }} spacing={2}>
        <ErrorMessage
          errors={errors}
          name={`employment_details.employer.${index}.files`}
          render={({ message }) => {
            if (message)
              return (
                <Alert style={{ textTransform: "small" }} severity="error">
                  {message}
                </Alert>
              );

            return <>This is an error alert — check it out!</>;
          }}
        />
      </Stack>

      <Stack sx={{ width: "100%" }} style={{ marginTop: "10px" }} spacing={2}>
        <div className="mt-1 mb-1">
          {getValues(
            `employment_details.employer.${index}.validation_message_status`
          ).map(
            (item) =>
              item.message && <Alert severity="warning">{item.message}</Alert>
          )}
        </div>
      </Stack>

      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete employer  ${
            getValues(`employment_details.employer.${index}.employer_name`) ||
            index + 1
          } ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              // setTimeout(()=>{
              // remove(deleteIndex);
              // },500)
              let body = {
                trn_id: trn_id,
                source_id: getValues(`employment_details.employer.${index}.id`),
                document_id: "3",
              };
              console.warn("Check Delete Source", body);
              setOpen(false);
              await doDelete({
                trn_id: trn_id,
                source_id: getValues(`employment_details.employer.${index}.id`),
                document_id: "3",
              })
                .then((result) => {
                  console.log("Delete Doc Res", result);
                  console.log(
                    "INIF:719",
                    result,
                    watch(`employment_details.employer`)?.length
                  );

                  if (
                    result.data.status === 200 &&
                    getValues(`employment_details.employer`)?.length === 1
                  ) {
                    console.log(
                      "INIF:719",
                      getValues(`employment_details.employer`)?.length
                    );
                    setValue(`employment_details.response`, "No");
                  }
                })
                .catch((deleteDocError) => {
                  console.log("Delete Doc Error", deleteDocError);
                });
              setOpen(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={comment}
        onClose={() => {
          setComment(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add/Edit Comment</DialogTitle>
        <DialogTitle id="alert-dialog-title">
          <div className="row" style={{ minWidth: "400px" }}>
            <div className="col-sm-12">
              <InputTextArea
                label="Please Explain"
                register={register(
                  `employment_details.employer.${index}.comment`
                )}
                placeholder="Please Explain"
              />
            </div>
          </div>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={async () => {
              setComment(false);
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={employmentIncomeProof}
        onClose={() => {
          setDisplayContent(0);
          setEmploymentIncomeProof(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              <strong>IMPORTANT!</strong> In order to calculate and verify your
              employment income, if you are currently employed, we will need you
              to upload PDF’s of your earnings statements (paystubs) downloaded
              directly from your payroll website. Please do NOT upload pictures
              or scanned images, and please do NOT “print to PDF”, “save as” or
              otherwise modify your PDF documents in any way as doing so can
              render your documents unverifiable. If you have questions, you can
              find answers to frequently-asked questions below by clicking the
              relevant question in blue.
            </p>
            <small
              className="app-blue-color cursor-pointer"
              onClick={() => setDisplayContent(1)}
            >
              Why original PDF’s?
            </small>
            <small className={`mb-2 ${displayContent === 1 ? "" : "d-none"}`}>
              <br />
              The Docuverus software directly imports original PDF documents,
              which allows our system to more accurately read and validate your
              documents, expedites processing of your application, and can help
              to qualify you more quickly.
            </small>
            <br />
            <div style={{ height: "5px" }} />
            <small
              className="app-blue-color cursor-pointer"
              onClick={() => setDisplayContent(2)}
            >
              Newly employed?
            </small>
            <small className={`mb-2 ${displayContent === 2 ? "" : "d-none"}`}>
              <br />
              If you are newly employed and do not yet have access to original
              PDF’s of your earnings statements, then please check the box to
              the below, which will allow you to upload a PDF of your offer
              letter.
            </small>
            <br />
            <div style={{ height: "5px" }} />

            <small
              className="app-blue-color cursor-pointer"
              onClick={() => setDisplayContent(3)}
            >
              Self-employed?
            </small>
            <small className={`mb-2 ${displayContent === 3 ? "" : "d-none"}`}>
              <br />
              If you are self-employed and do not receive earnings statements /
              paystubs, then in order to verify your income, we will need for
              you to upload original PDF’s of your 3 most recent personal bank
              statements downloaded directly from your online bank account,
              which you can upload under the banking section below.
            </small>
            <br />
            <div style={{ height: "5px" }} />

            {/* <small
              className="app-blue-color cursor-pointer"
              onClick={() => setDisplayContent(4)}
            >
              Don’t have access to original PDF’s?
            </small>
            <small className={`mb-2 ${displayContent === 4 ? "" : "d-none"}`}>
              <br />
              If you do not have access to original PDF’s of your earnings
              statements / paystubs, then check the box below to certify this.
              You can then upload image documents of your earnings statements in
              the document upload box below.
            </small>
            <br /> */}
            <div style={{ height: "5px" }} />

            <small
              className="app-blue-color cursor-pointer"
              onClick={() => setDisplayContent(5)}
            >
              Have non-employment income?
            </small>
            <small className={`mb-2 ${displayContent === 5 ? "" : "d-none"}`}>
              <br />
              If you wish to upload proof of non-employment income, you can
              upload relevant documents in the “Proof of Non-Employment Income”
              section below.
            </small>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEmploymentIncomeProof(false);
              setDisplayContent(0);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Source;
