import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import ApplicantDetail from "./applicant-detail/applicant-detail";
import Contacts from "./contacts/contacts";
import FinantialDetails from "./finantial-details/finantial-details";
import Others from "./others/others";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import { useSelector, useDispatch } from "react-redux";
import { sideNavAction } from "../../../store/identification/sideMenuSlice";
import {
  useGetDetailsQuery,
  identificationApi,
  useGetSectionStatusQuery,
} from "../../../services/identificationApi";
import { useSaveContexttualMutation } from "../../../services/identificationApi";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { formSubmitStatusRest } from "../../../store/registration/fromSubmitSlice";
// import AiOutlinePlus from "@mui/icons-material/ExpandMore";
import { BsCheck2Circle } from "react-icons/bs";
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  AccordionDetails,
  Snackbar,
} from "@mui/material";
import { tabsStateAction } from "../../../store/identification/tabStateSlice";
import MuiAlert from "@mui/material/Alert";
import { AiOutlinePlus } from "react-icons/ai";

import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";
import SupportSection from "../../common/SupportSection/SupportSection";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ApplicantDetails = forwardRef((props, ref) => {
  const [clickHere, setClickHere] = React.useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitBtnRef = useRef(null);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const pet_id = useSelector((state) => state.identification.sideMenuSlice);
  let sectionCompleteStatus = useGetSectionStatusQuery(trn_id);

  dispatch(sideNavAction.applicationStatusState(sectionCompleteStatus.data));

  const formSectionSubmit = useSelector(
    (state) => state.formSubmit.fromSectionSubmit
  );

  const { data, isLoading, isError } = useGetDetailsQuery(trn_id);
  const [saveContextual, result] = useSaveContexttualMutation();

  const sectionStatus = useGetSectionStatusQuery(trn_id);

  useEffect(() => {
    document.title = "Contextual Questions";
  }, []);

  useEffect(() => {
    if (data) {
      let fields = data?.have_pet?.fields;
      dispatch(
        sideNavAction.uploadPetImageId({
          data: fields,
          type: "APICALL",
        })
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    (async () => {
      if (formSectionSubmit) {
        if (!(await handleSubmitValidation("ApplicantDetails"))) return;
        if (!(await handleSubmitValidation("Contacts"))) return;
        if (!(await handleSubmitValidation("Finance"))) return;
        if (!(await handleSubmitValidation("Others"))) return;
        submitBtnRef?.current?.click();
      }
    })();
  }, [formSectionSubmit]);

  const notEmptyFieldsMethod = (
    value,
    path,
    createError,
    myFailureMessage,
    setNameError
  ) => {
    try {
      if (value) {
        setNameError(false);
        return true;
      }
      setNameError(true);
      return createError({ path, message: myFailureMessage });
    } catch (error) {
      console.error("🚀 ~ file: identification.js ~ line 96 ~ error", error);
    }
  };

  const today = new Date().setHours(0, 0, 0, 0);

  const schema = yup.object().shape({
    anyone_over_18: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid name")
              .required("Required"),
            last_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid name")
              .required("Required"),
            email: yup
              .string()
              .email("Invalid email format")
              .required("Required"),
            mobile: yup.string().nullable(),
            financially_responsible: yup.bool().nullable(),
          })
        ),
      }),

      // ProfileTabFieldID: yup.string().when("required", {
      //   is: true,
      //   then: yup.string().required("required"),
      // }),
      Question: yup.string(),
    }),
    is_co_signer: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),

      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            last_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            email: yup
              .string()
              .email("Invalid email format")
              .required("Required"),
            mobile: yup.string().nullable(),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),

    co_occupants: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()

              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            last_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            dob: yup
              .string()
              .nullable()
              .required("Required"),
            relationship: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            ssn: yup
              .string()
              .nullable()
              .typeError()
              .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed")
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .notRequired(),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    reference: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            last_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            email: yup
              .string()
              .email("Invalid email format")
              .required("Required"),
            mobile: yup.string().nullable(),
            relationship: yup.string().required('Required').matches(/^$|^\S+.*/g, "Enter a valid value")
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    emergency_reference: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            first_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            last_name: yup
              .string()
              
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            email: yup
              .string()
              .email("Invalid email format")
              .required("Required"),
            mobile: yup.string().nullable(),
            relationship: yup.string().required('Required').matches(/^$|^\S+.*/g, "Enter a valid value")
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    renters_insurance: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            insurance_companyName: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            policy_number: yup
              .string()
              .matches(/^[A-Za-z0-9_]+$/, "Only Alphanumeric Allowed") //Make Number with Letters
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .required("Required"),
            policy_expirationdate: yup
              .string()
              .nullable()
              .typeError("required")
              .required("Required")
              // .test("policy_number", "Future Date Required", (value) => {
              //   if (!value) return true;
              //   var now = new Date();
              //   now.setHours(0, 0, 0, 0);
              //   return value > now ? true : false;
              // })
              // .transform((curr, orig) => (orig == "" ? null : curr))
              ,
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    eviction_or_nonpayment: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value").max(5000, 'Text size exceeds a limit of 5000!'),
        }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    contingencies: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value").max(5000, 'Text size exceeds a limit of 5000!'),
        }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    filed_for_bankruptcy: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value").max(5000, 'Text size exceeds a limit of 5000!'),
        }),
      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    is_student: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            collegeuniversity_name: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            activities: yup.string().matches(/^$|^\S+.*/g, "Enter a valid value"),
            major: yup.string().matches(/^$|^\S+.*/g, "Enter a valid value"),
            anticipatedgraduation_date: yup
              .string()
              .typeError("Required")
              .nullable()
              .transform((curr, orig) => (orig == "" ? null : curr))
              .required("Required")
              .test("date", "Future date required", (value) => {
                if (!value) return true;
                return moment(value).isAfter(new Date());
              }),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    have_vehicle: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value == "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            vehicle_year: yup
              .string()
              .typeError("Required")
              .nullable()
              .matches(/^(\d+[0-9])$/, "Only Allowed Numbers")
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .test("validate-year", function (value) {
                const { path, createError } = this;
                const myFailureMessage = "Please enter valid year";
                try {
                  if (value) {
                    let minYear = 1900;
                    let maxYear = moment(new Date()).format("YYYY");
                    let addOneYear = parseInt(maxYear) + 1;
                    console.log("Check Max Year", maxYear, addOneYear);
                    if (
                      parseInt(value) < minYear ||
                      parseInt(value) > addOneYear
                    ) {
                      return createError({ path, message: myFailureMessage });
                    } else {
                      return true;
                    }
                  }
                } catch (error) {
                  console.log(
                    "year validation error in applicant details",
                    error
                  );
                }
              })
              .required("Required"),
            vehicle_make: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            vehicle_model: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            vehicle_color: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            vehicle_licenseplate: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            vehicle_monthlypayment: yup
              .string()
              .nullable()
              .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    have_pet: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value == "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      fields: yup.array().when("response", {
        is: (value) => {
          return value === "Yes";
        },
        then: yup.array().of(
          yup.object().shape({
            pet_type: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            pet_breed: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
            pet_weight: yup
              .string()
              .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
            pet_name: yup.string().nullable().matches(/^$|^\S+.*/g, "Enter a valid value"),
            service_animal: yup.boolean(),
          })
        ),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    do_you_smoke: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value === "true";
        },
        then: yup
          .string()
          .nullable()
          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
    convicted_for_crime: yup.object().shape({
      required: yup.string(),
      display: yup.string(),
      response: yup.string().when("required", {
        is: (value) => {
          return value === "true";
        },
        then: yup
          .string()
          .nullable()

          .typeError("Response Required")
          .required("Response Required"),
        otherwise: yup.string().nullable(),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            
            return value === "Yes";
          },
          then: yup.string().typeError("Required").required("Required").max(5000, 'Text size exceeds a limit of 5000!'),
        }),

      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("Required"),
      }),
      Question: yup.string(),
    }),
  });
  const methods = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
    mode: "all",
  });
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  useEffect(() => {
    methods.reset(data);
  }, [data, methods]);

  const ApplicantDetailStatus = useSelector(
    (state) => state.identification.tabsStateSlice.ApplicantDetailStatus
  );

  const ContactsStatus = useSelector(
    (state) => state.identification.tabsStateSlice.ContactsStatus
  );

  const FinantialDetailStatus = useSelector(
    (state) => state.identification.tabsStateSlice.FinantialDetailStatus
  );

  const OtherStatus = useSelector(
    (state) => state.identification.tabsStateSlice.OtherStatus
  );

  const ApplicantErr = useSelector(
    (state) => state.identification.tabsStateSlice.ApplicantErr
  );
  // useEffect(() => {
  //   if (sectionStatus?.data?.applicant_details_status) {
  //     dispatch(tabsStateAction.Other(true));
  //   }
  // }, [sectionStatus?.data, dispatch]);

  const handleSubmitValidation = async (parms, submit = true) => {
    console.log("HandleSubmitCalled:813", parms)
    if (parms === "ApplicantDetails") {
      const localSchema = yup.object().shape({
        anyone_over_18: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value == "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                first_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value").matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                last_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value").matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                email: yup
                  .string()
                  .email("Invalid email format")
                  .required("Required"),
                mobile: yup.string().nullable(),
                financially_responsible: yup.bool().nullable(),
              })
            ),
          }),

          // ProfileTabFieldID: yup.string().when("required", {
          //   is: true,
          //   then: yup.string().required("required"),
          // }),
          Question: yup.string(),
        }),
        is_co_signer: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value == "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                first_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                last_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                email: yup
                  .string()
                  .email("Invalid email format")
                  .required("Required"),
                mobile: yup.string().nullable(),
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        co_occupants: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value == "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                first_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                last_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                dob: yup
                  .string()
                  .nullable()
                  .required("Required") ,
                relationship: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                ssn: yup
                  .string()
                  .nullable()
                  .typeError()
                  .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed").matches(/^$|^\S+.*/g, "Enter a valid value")
                  .notRequired(),
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (!submit) return false;
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.ApplicantDetail(false));
          dispatch(tabsStateAction.ApplicantErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms == "Contacts") {
      const localSchema = yup.object().shape({
        reference: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value == "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                first_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                last_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                email: yup
                  .string()
                  .email("Invalid email format")
                  .required("Required"),
                mobile: yup.string().nullable(),
                relationship: yup.string().required('Required').matches(/^$|^\S+.*/g, "Enter a valid value")
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        emergency_reference: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                first_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                last_name: yup
                  .string()
                  .matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                email: yup
                  .string()
                  .email("Invalid email format")
                  .required("Required"),
                mobile: yup.string().nullable(),

                relationship: yup.string().required('Required').matches(/^$|^\S+.*/g, "Enter a valid value")
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (!submit) return false;
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Contacts(false));
          dispatch(tabsStateAction.ApplicantErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "Finance") {
      const localSchema = yup.object().shape({
        renters_insurance: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                insurance_companyName: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                policy_number: yup
                  .string()
                  .matches(/^[A-Za-z0-9_]+$/, "Only Numbers Allowed").matches(/^$|^\S+.*/g, "Enter a valid value")
                  .required("Required"),
                policy_expirationdate: yup
                  .string()
                  .nullable()
                  .transform((curr, orig) => (orig === "" ? null : curr))
                  // .test("policy_number", "Future Date Required", (value) => {
                  //   if (!value) return true;
                  //   var now = new Date();
                  //   now.setHours(0, 0, 0, 0);
                  //   return value > now ? true : false;
                  // })
                  .required("Required"),
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        eviction_or_nonpayment: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          explanation: yup
            .string()
            .nullable()
            .when("response", {
              is: (value) => {
                return value === "Yes";
              },
              then: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value").max(5000, 'Text size exceeds a limit of 5000!'),
            }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        contingencies: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          explanation: yup
            .string()
            .nullable()
            .when("response", {
              is: (value) => {
                return value === "Yes";
              },
              then: yup.string().typeError("Required").required("Required").matches(/^$|^\S+.*/g, "Enter a valid value").max(5000, 'Text size exceeds a limit of 5000!'),
            }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        filed_for_bankruptcy: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          explanation: yup
            .string()
            .nullable()
            .when("response", {
              is: (value) => {
                return value === "Yes";
              },

              then: yup
                .string()
                .nullable()
                .typeError("Required")
                .required("Required").matches(/^$|^\S+.*/g, "Enter a valid value").max(5000, 'Text size exceeds a limit of 5000!'),
            }),
          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          if (!submit) return false;
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.FinantialDetail(false));
          dispatch(tabsStateAction.ApplicantErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "Others") {
      const localSchema = yup.object().shape({
        is_student: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                collegeuniversity_name: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                activities: yup.string().matches(/^$|^\S+.*/g, "Enter a valid value"),
                major: yup.string().matches(/^$|^\S+.*/g, "Enter a valid value"),
                anticipatedgraduation_date: yup
                  .string()
                  .nullable()
                  .transform((curr, orig) => (orig === "" ? null : curr))
                  .required("Required"),
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        have_vehicle: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()

              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                vehicle_year: yup
                  .string()
                  .nullable()
                  .matches(/^(\d+[0-9])$/, "Only Allowed Numbers").matches(/^$|^\S+.*/g, "Enter a valid value")
                  .test("validate-year", function (value) {
                    const { path, createError } = this;
                    const myFailureMessage = "Please enter valid year";
                    try {
                      if (value) {
                        let minYear = 1900;
                        let maxYear = moment(new Date()).format("YYYY");
                        let addOneYear = parseInt(maxYear) + 1;
                        console.log("Check Max Year", maxYear, addOneYear);
                        if (
                          parseInt(value) < minYear ||
                          parseInt(value) > addOneYear
                        ) {
                          return createError({
                            path,
                            message: myFailureMessage,
                          });
                        } else {
                          return true;
                        }
                      }
                    } catch (error) {
                      console.log(
                        "year validation error in applicant details",
                        error
                      );
                    }
                  })
                  .typeError("Required")
                  .required("Required"),
                vehicle_make: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                vehicle_model: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                vehicle_color: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                vehicle_licenseplate: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                vehicle_monthlypayment: yup
                  .string()
                  .nullable()
                  .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        have_pet: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()

              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          fields: yup.array().when("response", {
            is: (value) => {
              return value === "Yes";
            },
            then: yup.array().of(
              yup.object().shape({
                pet_type: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                pet_breed: yup.string().required("Required").matches(/^$|^\S+.*/g, "Enter a valid value"),
                pet_weight: yup
                  .string()
                  .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
                pet_name: yup.string().matches(/^$|^\S+.*/g, "Enter a valid value"),
                service_animal: yup.boolean(),
              })
            ),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        do_you_smoke: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value == "true";
            },
            then: yup
              .string()
              .nullable()

              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
        convicted_for_crime: yup.object().shape({
          required: yup.string(),
          display: yup.string(),
          response: yup.string().when("required", {
            is: (value) => {
              return value === true;
            },
            then: yup
              .string()
              .nullable()

              .typeError("Response Required")
              .required("Response Required"),
            otherwise: yup.string().nullable(),
          }),
          explanation: yup
            .string()
            .nullable()
            .when("response", {
              is: (value) => {
                return value === "Yes";
              },
              then: yup.string().typeError("Required").required("Required"),
            }),

          ProfileTabFieldID: yup.string().when("required", {
            is: true,
            then: yup.string().required("Required"),
          }),
          Question: yup.string(),
        }),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          dispatch(tabsStateAction.Other(false));
          dispatch(tabsStateAction.ApplicantErr(true));
          submitBtnRef?.current?.click();
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    }
  };

  const handleChange = async (parms) => {
    const applicantDetailsSchema = yup.object().shape({
      anyone_over_18: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            console.log("1369:", value == "true");
            console.log("1369:", typeof value);
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        fields: yup.array().when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.array().of(
            yup.object().shape({
              first_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              last_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              email: yup
                .string()
                .email("Invalid email format")
                .required("Required"),
              mobile: yup.string().nullable(),
              financially_responsible: yup.bool().nullable(),
            })
          ),
        }),

        // ProfileTabFieldID: yup.string().when("required", {
        //   is: true,
        //   then: yup.string().required("required"),
        // }),
        Question: yup.string(),
      }),
      is_co_signer: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        fields: yup.array().when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.array().of(
            yup.object().shape({
              first_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              last_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              email: yup
                .string()
                .email("Invalid email format")
                .required("Required"),
              mobile: yup.string().nullable(),
            })
          ),
        }),

        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
      co_occupants: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        fields: yup.array().when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.array().of(
            yup.object().shape({
              first_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              last_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              dob: yup.string().nullable().required("Required"),
              relationship: yup
                .string()
                .required("Required")
                .matches(/^$|^\S+.*/g, "Enter a valid value"),
              ssn: yup
                .string()
                .nullable()
                .typeError()
                .matches(/^(\d*[0-9])|^$/g, "Only Numbers Allowed")
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .notRequired(),
            })
          ),
        }),

        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
    });
    const validateapplicantDetails = await applicantDetailsSchema.isValid(
      methods.getValues()
    );
    const contactsSchema = yup.object().shape({
      reference: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        fields: yup.array().when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.array().of(
            yup.object().shape({
              first_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              last_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              email: yup
                .string()
                .email("Invalid email format")
                .required("Required"),
              mobile: yup.string().nullable(),

              relationship: yup
                .string()
                .required("Required")
                .matches(/^$|^\S+.*/g, "Enter a valid value"),
            })
          ),
        }),

        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
      emergency_reference: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        fields: yup.array().when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.array().of(
            yup.object().shape({
              first_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              last_name: yup
                .string()
                
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              email: yup
                .string()
                .email("Invalid email format")
                .required("Required"),
              mobile: yup.string().nullable(),
              relationship: yup
                .string()
                .required("Required")
                .matches(/^$|^\S+.*/g, "Enter a valid value"),
            })
          ),
        }),

        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
    });
    const validateContacts = await contactsSchema.isValid(methods.getValues());
    const financeSchema = yup.object().shape({
      renters_insurance: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        fields: yup.array().when("response", {
          is: (value) => {
            return value == "Yes";
          },
          then: yup.array().of(
            yup.object().shape({
              insurance_companyName: yup
                .string()
                .required("Required")
                .matches(/^$|^\S+.*/g, "Enter a valid value"),
              policy_number: yup
                .string()
                .matches(/^[A-Za-z0-9_]+$/, "Only Numbers Allowed")
                .matches(/^$|^\S+.*/g, "Enter a valid value")
                .required("Required"),
              policy_expirationdate: yup
                .string()
                .nullable()
                .transform((curr, orig) => (orig == "" ? null : curr))
                // .test("policy_number", "Future Date Required", (value) => {
                //   if (!value) return true;
                //   var now = new Date();
                //   now.setHours(0, 0, 0, 0);
                //   return value > now ? true : false;
                // })
                .required("Required"),
            })
          ),
        }),

        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
      eviction_or_nonpayment: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        explanation: yup
          .string()
          .nullable()
          .when("response", {
            is: (value) => {
              return value == "Yes";
            },
            then: yup
              .string()
              .typeError("Required")
              .required("Required")
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .max(5000, "Text size exceeds a limit of 5000!"),
          }),

        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
      contingencies: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        explanation: yup
          .string()
          .nullable()
          .when("response", {
            is: (value) => {
              return value == "Yes";
            },
            then: yup
              .string()
              .typeError("Required")
              .required("Required")
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .max(5000, "Text size exceeds a limit of 5000!"),
          }),

        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
      filed_for_bankruptcy: yup.object().shape({
        required: yup.string(),
        display: yup.string(),
        response: yup.string().when("required", {
          is: (value) => {
            return value == "true";
          },
          then: yup
            .string()
            .nullable()
            .typeError("Response Required")
            .required("Response Required"),
          otherwise: yup.string().nullable(),
        }),
        explanation: yup
          .string()
          .nullable()
          .when("response", {
            is: (value) => {
              return value == "Yes";
            },
            then: yup
              .string()
              .typeError("Required")
              .required("Required")
              .matches(/^$|^\S+.*/g, "Enter a valid value")
              .max(5000, "Text size exceeds a limit of 5000!"),
          }),
        ProfileTabFieldID: yup.string().when("required", {
          is: true,
          then: yup.string().required("Required"),
        }),
        Question: yup.string(),
      }),
    });
    const validateFinance = await financeSchema.isValid(methods.getValues());
    if (parms === "ApplicantDetails") {
      try {
        if (validateapplicantDetails) {
          methods.clearErrors();
          dispatch(tabsStateAction.ApplicantDetail(true));
          dispatch(tabsStateAction.ApplicantErr(false));
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.ApplicantDetail(false));
          dispatch(tabsStateAction.ApplicantErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms == "Contacts") {
      try {
        if (validateapplicantDetails) {
          if (validateContacts) {
            methods.clearErrors();
            dispatch(tabsStateAction.Contacts(true));
            dispatch(tabsStateAction.ApplicantErr(false));
          } else {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.Contacts(false));
            dispatch(tabsStateAction.ApplicantErr(true));
          }
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.ApplicantDetail(false));
          dispatch(tabsStateAction.ApplicantErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms == "Finance") {
      try {
        if (validateapplicantDetails) {
          if (validateContacts) {
            if (validateFinance) {
              methods.clearErrors();
              dispatch(tabsStateAction.FinantialDetail(true));
              dispatch(tabsStateAction.ApplicantErr(false));
            } else {
              submitBtnRef?.current?.click();
              dispatch(tabsStateAction.FinantialDetail(false));
              dispatch(tabsStateAction.ApplicantErr(true));
            }
          } else {
            submitBtnRef?.current?.click();
            dispatch(tabsStateAction.Contacts(false));
            dispatch(tabsStateAction.ApplicantErr(true));
          }
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.ApplicantDetail(false));
          dispatch(tabsStateAction.ApplicantErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    }
  };

  const { hash } = useLocation();

  const onSubmit = async (fromData) => {
    let havePet = {
      ...fromData.have_pet,
      // pet_id: pet_id.uploadPetImageId,
      fields: fromData.have_pet?.fields?.map((petDetail, index) => {
        return {
          ...petDetail,
          pet_id: pet_id.uploadPetImageId[index]?.id,
        };
      }),
    };
    let questions = [];
    questions[0] = fromData.anyone_over_18;
    questions[1] = fromData.co_occupants;
    questions[2] = fromData.is_co_signer;
    questions[3] = fromData.reference;
    questions[4] = fromData.emergency_reference;
    questions[5] = fromData.renters_insurance;
    questions[6] = fromData.eviction_or_nonpayment;
    questions[7] = fromData.contingencies;
    questions[8] = fromData.filed_for_bankruptcy;
    questions[9] = fromData.is_student;
    questions[10] = fromData.have_vehicle;
    questions[11] = havePet;
    questions[12] = fromData.convicted_for_crime;
    if (fromData?.do_you_smoke?.display == "true") {
      questions.push(fromData.do_you_smoke);
    }
    let tempObj = {
      trn_id,
      questions: questions,
    };
    const response = await toast.promise(saveContextual(tempObj), {
      pending: "Saving",

      error: "Saving failed 🤯",
    });
    if (response) {
      dispatch(sideNavAction.uploadPetImageId(null));
      dispatch(formSubmitStatusRest());
      dispatch(tabsStateAction.Income(false));
      dispatch(tabsStateAction.sideNavApplicantReducer(true));
      navigate("/identification/incomeDetail");
    }
    // saveContextual(tempObj).then((response) => {
    //   console.log("Check response for Questions field", response);
    //   if (response.data.success) {
    //     dispatch(sideNavAction.uploadPetImageId(null));
    //     dispatch(formSubmitStatusRest());
    //     navigate("/identification/incomeDetail");
    //   }
    // });
  };

  useEffect(() => {
    dispatch(tabsStateAction.sideNavApplicantReducer(false));
  }, []);

  useEffect(() => {
    if (!hash) return;
    if (hash == "#ad") {
      navigate(-1);
      return dispatch(tabsStateAction.ApplicantDetail(false));
    }
    if (hash == "#contacts") {
      navigate(-1);
      return handleChange("ApplicantDetails");
    }
    if (hash == "#fd") {
      navigate(-1);
      return handleChange("Contacts");
    }
    if (hash == "#others") {
      navigate(-1);
      return handleChange("Finance");
    }
  }, [hash]);

  if (isLoading) return <h1>Loading</h1>;
  if (isError) return <h1>Error</h1>;

  return (
    <div id="app-details-tabs">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <fieldset disabled={viewOnlyFlag}>
            {/* Code here  */}
            <Accordion
              expanded={!ApplicantDetailStatus}
              onChange={() => {
                dispatch(tabsStateAction.ApplicantDetail(false));
              }}
            >
              <AccordionSummary
                expandIcon={ApplicantDetailStatus && <AiOutlinePlus />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  {ApplicantDetailStatus && <BsCheck2Circle color="green" />}{" "}
                  Applicant Details
                </Typography>
              </AccordionSummary>
              <ApplicantDetail
                checkValidation={handleSubmitValidation}
                que={data}
              />
            </Accordion>

            {/* Contacts */}
            <Accordion
              expanded={ApplicantDetailStatus && !ContactsStatus}
              onChange={() => {
                handleChange("ApplicantDetails");
              }}
            >
              <AccordionSummary
                expandIcon={
                  (ApplicantDetailStatus && !ContactsStatus) || (
                    <AiOutlinePlus />
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  {ContactsStatus && <BsCheck2Circle color="green" />} Contacts
                </Typography>
              </AccordionSummary>
              <Contacts checkValidation={handleSubmitValidation} />
            </Accordion>
            {/* Finincail Details */}
            <Accordion
              expanded={ContactsStatus && !FinantialDetailStatus}
              onChange={() => {
                handleChange("Contacts");
              }}
            >
              <AccordionSummary
                expandIcon={
                  (ContactsStatus && !FinantialDetailStatus) || (
                    <AiOutlinePlus />
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  {FinantialDetailStatus && <BsCheck2Circle color="green" />}{" "}
                  Financial Details
                </Typography>
              </AccordionSummary>
              <FinantialDetails checkValidation={handleSubmitValidation} />
            </Accordion>
            {/* Others */}
            <Accordion
              expanded={FinantialDetailStatus && !OtherStatus}
              onChange={() => {
                handleChange("Finance");
              }}
            >
              <AccordionSummary
                expandIcon={
                  (FinantialDetailStatus && !OtherStatus) || <AiOutlinePlus />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  {OtherStatus && <BsCheck2Circle color="green" />} Other
                  Details
                </Typography>
              </AccordionSummary>
              <Others />
            </Accordion>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={ApplicantErr}
              key={1}
            >
              <Alert
                onClick={() => {
                  dispatch(tabsStateAction.ApplicantErr(false));
                }}
                severity="error"
              >
                {" "}
                Answer the required field <CloseIcon />
              </Alert>
            </Snackbar>
            {/* end here  */}
            <button
              ref={submitBtnRef}
              style={{ display: "none" }}
              type="submit"
              className="btn btn-info"
            >
              Next
            </button>
          </fieldset>
        </form>
      </FormProvider>
      <DevTool control={methods.control} />
      <ToastContainer />
      <div className="mt-4">
        <SupportSection clickHere={clickHere} setClickHere={setClickHere} />
      </div>
    </div>
  );
});

export default ApplicantDetails;
