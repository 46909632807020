import React from "react";
import { Controller, useFormContext, useController } from "react-hook-form";
import { useSelector } from "react-redux";
import "./custom.scss";
const CustomRadioButton = (props) => {
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  const required = props.notRequired === undefined ? true : !props.notRequired;
  //const selectedValue=props.selectedValue||0;
  const { name } = props.register;
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <Controller
        control={props.control}
        name={name}
        render={({ field }) => {
          console.log("Check Field Radio:24", field);
          return (
            <>
              <div className="i-a-block">
                <h5 className={`${error !== undefined && "text-danger"}`}>
                  {props.label} {required && <span className="red">*</span>}
                </h5>
                <div className="d-flex">
                  <div>
                    <input
                      type="radio"
                      disabled={props.disable || false}
                      id={`${name}yes`}
                      name={name}
                      {...field}
                      checked={
                        field.value && field.value.toLowerCase() == "yes"
                      }
                      value="Yes"
                      className="is-hidden"
                    />
                    <label
                      htmlFor={`${name}yes`}
                      className={`ms-1 btn1 btn1--yes ${
                        required && !field.value && "backgroundCRD"
                      } ${error !== undefined && "backgroundCRD"}`}
                    >
                      Yes
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      if (!viewOnlyFlag && props?.onClick) {
                        props?.onClick();
                      }
                    }}
                  >
                    <input
                      type="radio"
                      id={`${name}no`}
                      disabled={props.disable || false}
                      name={name}
                      checked={field.value && field.value.toLowerCase() == "no"}
                      {...field}
                      value="No"
                      className="is-hidden"
                    />
                    <label
                      htmlFor={`${name}no`}
                      className={`ms-1 btn1 btn1--no 
                    ${required && !field.value && "backgroundCRD"}
                    
                    ${error !== undefined && "backgroundCRD"}`}
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
      <div className="invalid-feedback" style={{ display: "block" }}>
        {" "}
        {error && error.message}
      </div>
    </>
  );
};

export default CustomRadioButton;
