import React, { useState, useEffect, useRef } from "react";
import IconButton from '@mui/material/IconButton';
import { useMobileScanSaveMutation } from '../../services/identificationApi';
import { useNavigate, useParams } from "react-router-dom";
import { parseDriverLicense } from "../backbarcode-scan/BackBarcodeScan";
import { AppBar, Toolbar, Typography, Box, Button ,CircularProgress} from '@mui/material';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { getOS } from "../camera-scan/CameraScan";
const CameraScanBack = () => {
    const [uploadImag, setUploadimg] = useState('')
    let { id, docId } = useParams();
    const currentURL = window.location.href;
    const [Save, Result] = useMobileScanSaveMutation();
    const navigate = useNavigate();

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            window.history.go(1);
        };
    }, [])

    const captureBack = async (base64) => {
        const rawFromUrl = window.location.href.split('raw=')[1];
        if (base64) {
            if (rawFromUrl) {
                const decodeRawData = decodeURIComponent(rawFromUrl);
                const licenseInfo = parseDriverLicense(decodeRawData);
                const MYOCRData = [
                    {
                        "alias": "License Id",
                        "confidence": 89,

                        "field_value_original": licenseInfo?.DAQ?.content.replace(' ', '') || '',
                        "id": 33,
                        "location": "",
                        "name": "license_id",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "First Name",
                        "confidence": 93,
                        "field_value_original": licenseInfo?.DAC?.content.replace(' ', '') || '',
                        "id": 2,
                        "location": "",
                        "name": "first_name",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Middle Name",
                        "confidence": 97,
                        "field_value_original": licenseInfo?.DAD?.content.replace(' ', '') || '',
                        "id": 34,
                        "location": "",
                        "name": "middle_name",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Last Name",
                        "confidence": 92,
                        "field_value_original": licenseInfo?.DCS?.content.replace(' ', '') || '',
                        "id": 3,
                        "location": "",
                        "name": "last_name",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Address",
                        "confidence": 71,
                        "field_value_original": licenseInfo?.DAG?.content.replace(' ', '') || '',
                        "id": 8,
                        "location": "",
                        "name": "address",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "City",
                        "confidence": 92,
                        "field_value_original": licenseInfo?.DAI?.content.replace(' ', '') || '',
                        "id": 11,
                        "location": "",
                        "name": "city",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "State",
                        "confidence": 92,
                        "field_value_original": licenseInfo?.DAJ?.content.replace(' ', '') || '',
                        "id": 1,
                        "location": "",
                        "name": "state",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Postal Code",
                        "confidence": 92,
                        "field_value_original": licenseInfo?.DAK?.content.replace(' ', '') || '',
                        "id": 9,
                        "location": "",
                        "name": "postal_code",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "DOB",
                        "confidence": 84,
                        "field_value_original": licenseInfo?.DBB?.content.replace(' ', '') || "",
                        "id": 4,
                        "location": "",
                        "name": "dob",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Issue Date",
                        "confidence": 84,
                        "field_value_original": licenseInfo?.DBD?.content.replace(' ', '') || "",
                        "id": 5,
                        "location": "",
                        "name": "issue_date",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Expiration Date",
                        "confidence": 84,
                        "field_value_original": licenseInfo?.DBA?.content.replace(' ', '') || "",
                        "id": 6,
                        "location": "",
                        "name": "expiration_date",
                        "section_name": "",
                        "validation_required": "NO"
                    },
                    {
                        "alias": "Doc/State Type",
                        "confidence": 92,
                        "field_value_original": licenseInfo?.DAJ?.content.replace(' ', '') || '',
                        "id": 156,
                        "location": "",
                        "name": "doc_state_type",
                        "section_name": "",
                        "validation_required": "No"
                    }
                ];

                Save({
                    "file": base64,
                    "document_id": 13,
                    "trn_id": id,
                    data: {
                        OCRData: MYOCRData,
                        raw_data: decodeRawData,
                    }
                }).then((res) => {
                    if (res?.data?.status === 200) {
                        navigate(`/scan/${id}/success`, { replace: true })
                    } else {
                      alert("Fail to save, try again");
                      setUploadimg("")
                    }
                  });

            } else {
                const MYOCRData = [
                    {
                        "alias": "License Id",
                        "confidence": 89,

                        "field_value_original": '',
                        "id": 33,
                        "location": "",
                        "name": "license_id",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "First Name",
                        "confidence": 93,
                        "field_value_original": '',
                        "id": 2,
                        "location": "",
                        "name": "first_name",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Middle Name",
                        "confidence": 97,
                        "field_value_original": '',
                        "id": 34,
                        "location": "",
                        "name": "middle_name",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Last Name",
                        "confidence": 92,
                        "field_value_original": '',
                        "id": 3,
                        "location": "",
                        "name": "last_name",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Address",
                        "confidence": 71,
                        "field_value_original": '',
                        "id": 8,
                        "location": "",
                        "name": "address",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "City",
                        "confidence": 92,
                        "field_value_original": '',
                        "id": 11,
                        "location": "",
                        "name": "city",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "State",
                        "confidence": 92,
                        "field_value_original": '',
                        "id": 1,
                        "location": "",
                        "name": "state",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Postal Code",
                        "confidence": 92,
                        "field_value_original": '',
                        "id": 9,
                        "location": "",
                        "name": "postal_code",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "DOB",
                        "confidence": 84,
                        "field_value_original": "",
                        "id": 4,
                        "location": "",
                        "name": "dob",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Issue Date",
                        "confidence": 84,
                        "field_value_original": "",
                        "id": 5,
                        "location": "",
                        "name": "issue_date",
                        "section_name": "",
                        "validation_required": "No"
                    },
                    {
                        "alias": "Expiration Date",
                        "confidence": 84,
                        "field_value_original": "",
                        "id": 6,
                        "location": "",
                        "name": "expiration_date",
                        "section_name": "",
                        "validation_required": "NO"
                    },
                    {
                        "alias": "Doc/State Type",
                        "confidence": 92,
                        "field_value_original": '',
                        "id": 156,
                        "location": "",
                        "name": "doc_state_type",
                        "section_name": "",
                        "validation_required": "No"
                    }
                ];
                Save({
                    "file": base64,
                    "document_id": 13,
                    "trn_id": id,
                    data: {
                        OCRData: MYOCRData,
                        raw_data: '',
                    }
                }).then((res) => {
                    if (res?.data?.status === 200) {
                        navigate(`/scan/${id}/success`, { replace: true })
                    } else {
                      alert("Fail to save, try again");
                      setUploadimg("")
                    }
                  });

            }
        }
    };

    const fileInputRef = useRef(null);

    const handleFileUpload = () => {
        fileInputRef.current.click();
    };
    
  
    useEffect(() => {
        setTimeout(() => { fileInputRef.current.click(); }, 500)

    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.heic|\.webp|\.bmp|\.svg)$/i;
        if (!allowedExtensions.test(file.name)) {
          alert('File format not supported');
          return;
        }
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64String = reader.result;
            console.log('Base64:', base64String);
            setUploadimg(base64String);
            if (base64String) {
                captureBack(base64String)
            }
           
          };
          reader.onerror = (error) => {
            console.error('Error converting file to base64:', error);
            alert("Error converting file, try again");
            setUploadimg("")
          };
        }
      };

    return (
        <>
            <Box>
                <AppBar sx={{ backgroundColor: '#2dc3e8' }} position="fixed">
                    <Toolbar>
                        <Typography variant="h6">Docuverus | Back Side</Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh', // Adjust the height as needed
                    }}
                >
                    {uploadImag ? <img width={'100%'} src={uploadImag} /> : <>
                        <input
                            type="file"
                            ref={fileInputRef}
                            accept={getOS()=='MacOS' || getOS()== 'iOS' ? "image/*" : '*'}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <Button sx={{ backgroundColor: '#2dc3e8', color: '#ffffff', mt: 2 }} onClick={handleFileUpload} variant="contained"> <IconButton component="span">
                            <PhotoCameraOutlinedIcon />
                        </IconButton> Snap / Upload document</Button>
                        <Typography variant="body2" color="textSecondary" mt={1}>
                            Snap a picture or upload from your phone
                        </Typography>
                        {Result.isError && <Typography variant="body2" color="error" mt={1}>
                            {Result.error.status == 401 ? 'The session has expired. Please close the tab and scan the barcode again.' : 'Fail to upload,Pls try again!'}
                        </Typography>}

                    </>}
                    {Result.isLoading && <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <CircularProgress size={24} sx={{ mr: 2 }} />
                        <Typography variant="body1">Please wait file Uploading...</Typography>
                    </Box>}
                </Box>
            </Box>
        </>
    );


};

export default CameraScanBack;