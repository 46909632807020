import React, { useEffect } from "react";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import UploadFile from "../../../common/uploadFile/uploadFile";
import InputField from "../../../registration/Inputs/InputField";
import { useFormContext } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteDocumentMutation } from "../../../../services/identificationApi";
import { BiTrash } from "react-icons/bi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import InputMaskField from "../../../registration/Inputs/InputMaskFieid";
import { useSelector } from "react-redux";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiInformationLine } from "react-icons/ri";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputTextArea from "../../../registration/Inputs/InputTextArea";

import {
  AccordionDetails,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Alert,
} from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { protocol } from "../../../../config";
import RadioInputField from "../../../registration/Inputs/RadioInputField";

const Ssn = (props) => {
  const [ssnInformation, setSnnInformation] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectOtherRadioButton, setSelectOtherRadioButton] =
    React.useState(false);

  const [deleteSSN, setDeleteSSN] = React.useState(false);

  const { register, watch, getValues, trigger, formState, setValue } =
    useFormContext();
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const [deleteDocument, result] = useDeleteDocumentMutation();

  const deleteDocumentHandler = (id) => {
    deleteDocument(id).then((response) => {
      console.log("Delete SSN Doc Response", response);
      if (response.data?.status == 200) {
        setDeleteSSN(false);
        setValue("ssn_data.file", "");
        setValue("ssn_data.file_image", "");
        setValue("ssn_data.availableToUpload_ssn", "No");
      } else {
        alert("Error in delete document");
        setDeleteSSN(false);
      }
    });
  };
  useEffect(() => {
    console.log("SSN:40", watch("ssn_data"));
    const subscribe = watch((value, { name, type }) => {
      console.log("Check value, name, type SSN line:24", value, name, type);
      if (type === "change" && name === `ssn_data.isUS_citizen`) {
        trigger("ssn_data.SSNNumber");
      }
      // if (name === "ssn_data.no_doc_reason" && type === "change") {
      //   setValue("ssn_data.other_reason", "");
      // }
      // if (name === "ssn_data.other_reason" && type === "change") {
      //   setValue("ssn_data.no_doc_reason", watch("ssn_data.other_reason"));
      //   setTimeout(() => {
      //     trigger("ssn_data.no_doc_reason");
      //   }, 3000);
      // }
    });
    return () => subscribe.unsubscribe;
  }, [setValue, trigger, watch]);

  // useEffect(() => {
  //   const subscribe = watch((value, { name, type }) => {
  //     console.log("Check value, name, type SSN line:24", value, name, type);
  //     if (type === "change" && name === `ssn_data.assign_no_doc_reason`) {
  //       if (
  //         watch("ssn_data.assign_no_doc_reason") ==
  //         "I don't currently have access to document(s), but can provide later"
  //       ) {
  //         setValue("ssn_data.other_reason", "");
  //       }
  //       if (watch("ssn_data.assign_no_doc_reason") == "I do not have SSN") {
  //         setValue("ssn_data.other_reason", "");
  //       }
  //     }
  //     // if (type === "change" && name === `ssn_data.availableToUpload_ssn`) {
  //     //   if (watch("ssn_data.availableToUpload_ssn") == "Yes") {
  //     //     setValue("ssn_data.other_reason", "");
  //     //   }
  //     //   if (watch("ssn_data.availableToUpload_ssn") == "No") {
  //     //     setValue(
  //     //       "ssn_data.assign_no_doc_reason",
  //     //       "I don’t currently have access to document(s), but can provide later"
  //     //     );
  //     //     setValue("ssn_data.other_reason", "");
  //     //   }
  //     // }
  //   });
  //   return () => subscribe.unsubscribe;
  // }, [watch, setValue]);
  const token = useSelector(
    (state) => state.persistedReducer.loginSlice.token
  );

 const hasSSToken = () =>{
    const url = getValues(
      `ssn_data.file_image`
    )
    if(!url)
    {
      return url;
    }
    else if(url.includes('?'))
    {
      return url+'&';
    }
    else{
      return url;
    }
}
  return (
    <AccordionDetails>
      <div className="ssn-t-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group cus-input-group mr-b30 mr-t30">
              <CustomRadioButton
                label="Are you a US citizen?"
                register={register("ssn_data.isUS_citizen")}
              />
            </div>
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-group cus-input-group mr-b30">
              <InputMaskField
                notRequired={
                  watch("ssn_data.isUS_citizen") == "No" ? true : false
                }
                maxLength={9}
                inputType="SSN"
                label="SSN Number"
                register={register("ssn_data.SSNNumber")}
                mask={"999-99-9999"}
              />
            </div>
          </div>

          <div className="col-sm-12">
            <div className="i-a-block">
              <CustomRadioButton
                onClick={() => {
                  if (!!watch("ssn_data.file")) {
                    setDeleteSSN(true);
                  }
                }}
                disable={!!watch("ssn_data.file")}
                label="Do you have proof of SSN available?"
                register={register("ssn_data.availableToUpload_ssn")}
              />
            </div>
          </div>
          {watch("ssn_data.availableToUpload_ssn") == "Yes" && (
            <div className="ssn-up-b-box">
              <div className="row w-100">
                <div className="col-sm-6 col-md-6 d-flex flex-column ps-3 justify-content-center col-lg-6">
                  <div className="ssn-text-block ">
                    <h4>
                      Proof of SSN
                      <RiInformationLine
                        className="ms-2 cursor-pointer"
                        fill="#D3AF51"
                        size={20}
                        onClick={() => {
                          setSnnInformation(true);
                        }}
                      />
                    </h4>
                    <h5>(SSN card or un-redacted W2)</h5>
                    <small className="highlight-text-background">
                      Click the (i) button above for details
                    </small>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="d-flex justify-content-end ">
                    {watch("ssn_data.file") !== null ? (
                      <>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            // src={`${getValues(
                            //   `ssn_data.file_image`
                            // )}`}

                            src={`${hasSSToken()}?token=${token}`}
                            className="img-thumbnail cursor-pointer"
                            alt=""
                            width={150}
                            height={150}
                            onClick={() =>
                              window
                              .open(
                                `${hasSSToken(false)}?token=${token}`,
                                "_blank"
                              )
                                .focus()
                            }
                          />





                          <button
                            type="button"
                            onClick={() => {
                              setOpen(true);
                            }}
                            title={`Delete`}
                            className="btn btn-link btn-sm text-danger"
                          >
                            <BiTrash /> Delete
                          </button>
                        </div>
                      </>
                    ) : (
                      <UploadFile
                        label="Upload"
                        className="mr-b10"
                        documentId={4}
                        SourceId={1}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {watch(`ssn_data.availableToUpload_ssn`) == "No" && (
            <>
             <p> <RadioInputField
                options={[
                  {
                    value:
                      "I don't currently have access to document(s), but can provide later",
                  },
                  {
                    value: "I do not have SSN",
                  },
                  {
                    value: "other",
                    label: "Other",
                  },
                ]}
                name="ssn_data.no_doc_reason"
              />
              {watch("ssn_data.no_doc_reason") == "other" && (
                <div>
                  <InputField
                    label="Please Explain"
                    register={register(`ssn_data.other_reason`)}
                    placeholder="Please Explain"
                  />
                </div>
              )}
              
            </p>
            </>
          )}
        </div>
        <Stack
          sx={{ width: "100%" }}
          style={{ textTransform: "capitalize", marginTop: "10px" }}
          spacing={2}
        >
          <ErrorMessage
            errors={formState.errors}
            name={`ssn_data.file`}
            render={({ message }) => {
              if (message) return <Alert severity="error">{message}</Alert>;

              return <></>;
            }}
          />
        </Stack>
      </div>
      <Dialog
        open={ssnInformation}
        onClose={() => {
          setSnnInformation(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Only upload clear, complete, straight documents.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setSnnInformation(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete document ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              setTimeout(() => {
                deleteDocumentHandler(
                  watch("ssn_data.application_document_id")
                );
              }, 200);
              setOpen(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteSSN}
        onClose={() => {
          setDeleteSSN(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setDeleteSSN(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setTimeout(() => {
                deleteDocumentHandler(
                  watch("ssn_data.application_document_id")
                );
              }, 200);
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </AccordionDetails>
  );
};

export default Ssn;
