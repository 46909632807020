import { ErrorMessage } from "@hookform/error-message";
import {
  AccordionDetails,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useAddEmployerSourceMutation,
  useDeleteSourceMutation,
  useGetIncomeBankingQuery,
} from "../../../../services/identificationApi";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import Source from "./source";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Loader from "react-loader-spinner";

import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";

const OtherIncome = (props) => {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const [doDelete, deleteResult] = useDeleteSourceMutation();
  const [deleteOtherIncome, setDeleteOtherIncome] = React.useState(false);
  const [disabledSouceBtn, setDisabledSourceBtn] = useState(false);
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [addEmployerSource, employerAddResult] = useAddEmployerSourceMutation();
  // const { data } = useGetIncomeBankingQuery(trn_id);
  const { data, refetch: refetchIncomeBanking } = useGetIncomeBankingQuery(trn_id);
  console.log(data,"dataincome")
  const { fields, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "other_income_details.otherincome", // unique name for your Field Array
    keyName: "controlId", //default to "id", you can change the key name
  });

  const deleteOtherIncomeSource = async () => {
    // setIsDeleting(true);
    let allOtherIncome = await getValues(`other_income_details.otherincome`);
    if (allOtherIncome?.length > 0) {
      for (let index = 0; index < allOtherIncome.length; index++) {
        const element = allOtherIncome[index];
        await doDelete({
          trn_id: trn_id,
          source_id: index + 1,
          document_id: "7",
        });
      }
    
      setDeleteOtherIncome(false);
      setValue(`other_income_details.response`, "No");
      await refetchIncomeBanking();
      
        // Reset the flag after deletion
  
    }
    // setIsDeleting(false); 
  };

  const [nextSection, setNextSection] = React.useState(false);
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  const navigate = useNavigate();
  // highlight-starts
  const debouncedSave = React.useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          setNextSection(await props.checkValidation("OtherIncome", false));
        })(),
      1000
    ),
    [] // will be created only once initially
  );
  // highlight-ends

  React.useEffect(() => {
    console.log("Check Other Income fields", fields);
    const subscription = watch((value, { name, type }) => {
      debouncedSave(name);
      if (type === "change" && name === `other_income_details.response`) {
        if (getValues(`other_income_details.response`) === "Yes") {
          addEmployerSource({
            trn_id: trn_id,
            document_id: "7",
            source_id: 1,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [addEmployerSource, fields.length, getValues, trn_id, watch, fields]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setDisabledSourceBtn(false);
  }, [fields?.length]);
  // const [open, setOpen] = useState(false);
  return (
    <AccordionDetails>
      <div className="other-in-body">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <CustomRadioButton
              onClick={() => {
                if (watch("other_income_details.employerCount") > 0) {
                  // setDeleteOtherIncome(true);
                  setOpen(true);
                }
              }}
              disable={getValues(`other_income_details.employerCount`)}
              register={register(`other_income_details.response`)}
              label="Do you have any other income (including alimony, child support, investment, retirement, SSI, disability or rental assistance, etc)?"
            />
          </div>
          {watch(`other_income_details.response`) === "Yes" && (
            <>
              {fields.map((field, index) => (
                <Source key={field.controlId} field={field} index={index} />
              ))}
              {fields?.length <= 2 && (
                <div className="col-sm-12">
                  <div className="add-text-btn flex1 text-center mr-t40">
                    {employerAddResult.isLoading ? (
                      <>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      </>
                    ) : (
                      <>
                        {fields?.length >= 1 && <button
                          onClick={() => {
                            setDisabledSourceBtn(true);
                            addEmployerSource({
                              trn_id: trn_id,
                              document_id: "7",
                              source_id: fields.length + 1,
                            })
                          }}
                          type="button"
                          disabled={disabledSouceBtn}
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                              fill="#2DC3E8"
                            ></path>
                          </svg>
                          Add another other income source
                        </button>}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <Stack
            sx={{ width: "100%" }}
            style={{ textTransform: "capitalize", marginTop: "10px" }}
            spacing={2}
          >
            <ErrorMessage
              errors={errors}
              name="other_income_details.otherincome"
              render={({ message }) => {
                if (message) return <Alert severity="error">{message}</Alert>;

                return <></>;
              }}
            />
          </Stack>
        </div>
      </div>
      {/* <Dialog
        open={deleteOtherIncome}
        onClose={() => {
          setDeleteOtherIncome(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setDeleteOtherIncome(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            disabled={deleteResult.isLoading}
            onClick={() => {
              deleteOtherIncomeSource();
            }}
           
          >
            {deleteResult.isLoading ? (
              <Loader
                type="Circles"
                color="#FFFFFF"
                height={20}
                width={20}
                radius={20}
                timeout={3000}
              />
            ) : (
              "Yes"
            )}
          </button>
        </DialogActions>
      </Dialog> */}
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You must delete all existing sources first.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValue(`other_income_details.response`, "Yes");
              // setConfirmDelete(false); // Ensures response does not change
              handleClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {viewOnlyFlag || nextSection && (
        <Button
          onClick={() => {
            navigate("#bank-info");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab
        </Button>
      )}
    </AccordionDetails>
  );
};

export default OtherIncome;
